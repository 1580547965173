<template>
  <div class="px-4 pt-2 mb-8">
    <v-row no-gutters>
      <v-col cols="3">
        <v-text-field
          label="Terms and Conditions Title"
          v-model="title"
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row no-gutters class="incentable-form-bold mb-3">
      Tems and Conditions Content
    </v-row>
    <v-row no-gutters>
      <v-col cols="9">
        <vue-editor
          v-model="content"
          :editor-toolbar="customToolbar"
        ></vue-editor>
        <div v-html="content"></div>
      </v-col>
    </v-row>
    <v-btn
      class="ml-0 mt-9"
      color="primary"
      @click="handleSave"
      :loading="loading"
      elevation="0"
      >Save</v-btn
    >
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
export default {
  components: {
    VueEditor,
  },
  created() {
    this.title = this.currentProgram.termsPage.title || "";
    this.terms = this.currentProgram.termsPage.body || "";
    this.content = this.currentProgram.termsPage.content || "";
  },
  data: () => ({
    customToolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ indent: "-1" }, { indent: "+1" }],
      ["clean"],
    ],
    editorSettings: {},
    data: null,
  }),
  computed: {
    loading() {
      return this.$store.getters.loading;
    },
    currentProgram() {
      return this.$store.getters.currentProgram;
    },
  },
  methods: {
    handleSave() {
      const payload = {
        termsPage: {
          title: this.title || "",
          body: this.terms || "",
          content: this.content || "",
        },
      };

      this.$store.dispatch("setLoading", true);
      this.$store
        .dispatch("updateCurrentProgram", payload)
        .then(() => {
          this.$store.dispatch("setLoading", false);
        })
        .catch(() => {
          this.$store.dispatch("setLoading", false);
        });
    },
  },
};
</script>

<style>
.ql-editor {
  min-height: 200px !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  font-family: "Roboto", sans-serif !important;
  color: #4e4e4e !important;
}
</style>
