<template>
  <div class="px-4 pt-2 mb-8">
    <v-row no-gutters>
      <v-col cols="3">
        <v-checkbox label="Sign In Open" v-model="signinOpen"></v-checkbox>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="3">
        <v-checkbox
          label="Registration Open"
          v-model="registrationOpen"
        ></v-checkbox>
      </v-col>
    </v-row>

    <v-divider class="mt-4"></v-divider>

    <v-row no-gutters class="mt-3">
      <v-col cols="9">
        <v-checkbox
          class="mb-n6"
          label="Phone number"
          v-model="phoneAtRegistration"
        ></v-checkbox>
        <span class="ml-9 caption"
          >Includes the phone number field on the registration form</span
        >
        <v-checkbox
          :disabled="!phoneAtRegistration"
          class="mt-n1 mb-n6 ml-9"
          label="Phone number is required"
          v-model="phoneAtRegistrationRequired"
        ></v-checkbox>
      </v-col>
    </v-row>

    <v-divider class="mt-9"></v-divider>

    <v-row no-gutters class="mt-3">
      <v-col cols="9">
        <v-checkbox
          class="mb-n6"
          label="Mobile number"
          v-model="mobileAtRegistration"
        ></v-checkbox>
        <span class="ml-9 caption"
          >Includes the mobile phone number field on the registration form</span
        >
        <v-checkbox
          :disabled="!mobileAtRegistration"
          class="mt-n1 mb-n6 ml-9"
          label="Mobile number is required"
          v-model="mobileAtRegistrationRequired"
        ></v-checkbox>
      </v-col>
    </v-row>

    <v-divider class="mt-9"></v-divider>

    <v-row no-gutters class="mt-3">
      <v-col cols="4">
        <v-checkbox
          class="mb-n6"
          label="Member Data Key"
          v-model="memberDataKeyAtRegistration"
        ></v-checkbox>
        <span class="ml-9 caption"
          >Includes the member Data Key field on the registration form</span
        >
        <v-checkbox
          :disabled="!memberDataKeyAtRegistration"
          class="mt-n1 mb-n6 ml-9"
          label="Mandatory"
          v-model="memberDataKeyAtRegistrationRequired"
        ></v-checkbox>
        <v-text-field
          v-model="memberDataKeyLabel"
          label="Member Data Key Label"
          class="ml-9"
          id="memberDataKeyLabel"
          name="memberDataKeyLabel"
          hint="Help members know exactly what you require them to enter, For examlple: Employee ID"
          persistent-hint
        ></v-text-field>
        <v-text-field
          v-model="memberDataKeyDescription"
          label="Member Data Key Description"
          class="ml-9"
          id="memberDataKeyDescription"
          name="memberDataKeyDescription"
          hint="Give additional guidence to members to what you need them to enter. For examlple: Your Employee ID is on your payslip"
          persistent-hint
        ></v-text-field>
      </v-col>
    </v-row>

    <v-divider v-if="isIncentableAdmin" class="mt-9"></v-divider>

    <v-row v-if="isIncentableAdmin" no-gutters class="mt-3">
      <v-col cols="4">
        <v-row no-gutters class="primary--text">
          Not yet available to customers. Incentable Admin only can see this
        </v-row>
        <v-checkbox
          class="mb-n6"
          label="Photo ID Image Upload"
          v-model="memberPhotoIdAtRegistration"
        ></v-checkbox>
        <span class="ml-9 caption"
          >Includes the member Photo ID file upload field on the registration
          form</span
        >
        <v-checkbox
          :disabled="!memberPhotoIdAtRegistration"
          class="mt-n1 mb-n6 ml-9"
          label="Mandatory"
          v-model="memberPhotoIdAtRegistrationRequired"
        ></v-checkbox>
        <v-text-field
          v-model="memberPhotoIdLabel"
          label="Member Photo ID Label"
          class="ml-9"
          id="memberPhotoIdLabel"
          name="memberPhotoIdLabel"
          hint="Help members know exactly which type of photo ID you require"
          persistent-hint
        ></v-text-field>
        <v-text-field
          v-model="memberPhotoIdDescription"
          label="Member Photo ID Description"
          class="ml-9"
          id="memberPhotoIdDescription"
          name="memberPhotoIdDescription"
          hint="Give additional guidence to members to what you need them to enter. For examlple: Your Employee ID is on your payslip"
          persistent-hint
        ></v-text-field>
      </v-col>
    </v-row>

    <v-divider class="mt-9"></v-divider>

    <v-row no-gutters class="mt-3">
      <v-col cols="9">
        <v-checkbox
          class="mb-n6"
          label="Add Company At Registration"
          v-model="addCompanyAtRegistration"
        ></v-checkbox>
        <span class="ml-9 caption"
          >Allows a member to add a new company at registration, and be linked
          to it</span
        >
        <v-checkbox
          :disabled="!addCompanyAtRegistration"
          class="mt-n1 mb-n6 ml-9"
          label="Mandatory"
          v-model="companyAtRegistrationRequired"
        ></v-checkbox>
      </v-col>
    </v-row>

    <v-divider class="mt-9"></v-divider>

    <v-row no-gutters class="mt-3">
      <v-col cols="4">
        <v-checkbox
          class="mb-n6"
          label="Company Data Key"
          v-model="companyDataKeyAtRegistration"
        ></v-checkbox>
        <span class="ml-9 caption"
          >Includes the company Data Key field on the registration form</span
        >
        <v-checkbox
          :disabled="!companyDataKeyAtRegistration"
          class="mt-n1 mb-n6 ml-9"
          label="Mandatory"
          v-model="companyDataKeyAtRegistrationRequired"
        ></v-checkbox>
        <v-text-field
          v-model="companyDataKeyLabel"
          label="Company Data Key Label"
          class="ml-9"
          id="companyDataKeyLabel"
          name="companyDataKeyLabel"
          hint="Help members know exactly what you require them to enter, For examlple: Account ID"
          persistent-hint
        ></v-text-field>
        <v-text-field
          v-model="companyDataKeyDescription"
          label="Company Data Key Description"
          class="ml-9"
          id="companyDataKeyDescription"
          name="companyDataKeyDescription"
          hint="Give additional guidence to members to what you need them to enter. For examlple: Your Account ID is on the top of your invoices"
          persistent-hint
        ></v-text-field>
      </v-col>
    </v-row>

    <v-divider class="mt-9"></v-divider>

    <v-row no-gutters class="mt-3">
      <v-col cols="9">
        <v-checkbox
          class="mb-n6"
          label="Join Company At Registration"
          v-model="joinCompanyAtRegistration"
        ></v-checkbox>
        <span class="ml-9 caption"
          >Allows a member to select an existing company at registration, and be
          linked to it</span
        >
        <v-checkbox
          :disabled="!joinCompanyAtRegistration"
          class="mt-n1 mb-n6 ml-9"
          label="Mandatory"
          v-model="companyAtRegistrationRequired"
        ></v-checkbox>
      </v-col>
    </v-row>

    <v-checkbox
      :disabled="!joinCompanyAtRegistration"
      class="mt-3 mb-n6 ml-9"
      label="Allow members to join multiple companies at the time of registration"
      v-model="multipleCompaniesAtRegistration"
    ></v-checkbox>

    <v-row no-gutters>
      <v-col cols="4">
        <v-text-field
          v-model="companyAtRegistrationLabel"
          label="Company Select Drop Down Label"
          class="ml-9 mt-4"
          id="companyAtRegistrationLabel"
          name="companyAtRegistrationLabel"
          hint="Eg: Store, Dealer, Retailer etc"
          persistent-hint
        ></v-text-field>
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="9">
        <v-select
          :disabled="!joinCompanyAtRegistration"
          :items="companyTags"
          item-text="tag"
          item-value="id"
          label="Company Registration Goups"
          v-model="companyRegistrationGroups"
          class="mb-n5 ml-9 mt-4"
          chips
          multiple
          deletable-chips
        ></v-select>
        <span class="ml-9 caption"
          >Select the company tags that members can use to filter companies by
          at the time of registration</span
        >
        <br /><span class="ml-9 caption"
          >This is OPTIONAL. Not selecting any tags here will allow all
          companies to be listed in the drop down</span
        >
      </v-col>
    </v-row>

    <v-row no-gutters>
      <v-col cols="4">
        <v-text-field
          v-model="companyRegistrationGroupLabel"
          label="Company Registration Group Label"
          class="ml-9 mt-4"
          id="companyRegistrationGroupLabel"
          name="companyRegistrationGroupLabel"
          hint="EG: Region, Franchise, State or Group. Based on the context of the company tags"
          persistent-hint
        ></v-text-field>
      </v-col>
    </v-row>

    <v-btn
      class="ml-0 mt-9"
      color="primary"
      @click="handleSave"
      :loading="loading"
      elevation="0"
      >Save</v-btn
    >
  </div>
</template>

<script>
export default {
  created() {
    const { currentProgram } = this.$store.state.program;
    this.registrationOpen = currentProgram.registrationOpen;
    this.addCompanyAtRegistration = currentProgram.addCompanyAtRegistration;
    this.companyAtRegistrationRequired =
      currentProgram.companyAtRegistrationRequired;
    this.joinCompanyAtRegistration = currentProgram.joinCompanyAtRegistration;
    this.companyAtRegistrationLabel = currentProgram.companyAtRegistrationLabel;
    this.multipleCompaniesAtRegistration =
      currentProgram.multipleCompaniesAtRegistration;
    this.companyRegistrationGroups = currentProgram.companyRegistrationGroups;
    this.companyRegistrationGroupLabel =
      currentProgram.companyRegistrationGroupLabel;
    this.mobileAtRegistration = currentProgram.mobileAtRegistration;
    this.mobileAtRegistrationRequired =
      currentProgram.mobileAtRegistrationRequired;
    this.phoneAtRegistration = currentProgram.phoneAtRegistration;
    this.phoneAtRegistrationRequired =
      currentProgram.phoneAtRegistrationRequired;
    this.memberDataKeyAtRegistration =
      currentProgram.memberDataKeyAtRegistration;
    this.memberDataKeyAtRegistrationRequired =
      currentProgram.memberDataKeyAtRegistrationRequired;
    this.companyDataKeyAtRegistration =
      currentProgram.companyDataKeyAtRegistration;
    this.companyDataKeyAtRegistrationRequired =
      currentProgram.companyDataKeyAtRegistrationRequired;
    this.memberDataKeyLabel = currentProgram.memberDataKeyLabel;
    this.memberDataKeyDescription = currentProgram.memberDataKeyDescription;
    this.companyDataKeyLabel = currentProgram.companyDataKeyLabel;
    this.companyDataKeyDescription = currentProgram.companyDataKeyDescription;
    this.memberPhotoIdLabel = currentProgram.memberPhotoIdLabel;
    this.memberPhotoIdDescription = currentProgram.memberPhotoIdDescription;
    this.memberPhotoIdAtRegistration =
      currentProgram.memberPhotoIdAtRegistration;
    this.memberPhotoIdAtRegistrationRequired =
      currentProgram.memberPhotoIdAtRegistrationRequired;
    this.signinOpen = currentProgram.signinOpen;
    this.$store.dispatch("loadRewardCatalogues");
    this.$store.dispatch("loadCompanyTags");
  },
  data: () => ({
    signinOpen: false,
    registrationOpen: false,
    addCompanyAtRegistration: false,
    companyAtRegistrationRequired: false,
    joinCompanyAtRegistration: false,
    multipleCompaniesAtRegistration: false,
    companyRegistrationGroups: [],
    phoneAtRegistration: false,
    phoneAtRegistrationRequired: false,
    mobileAtRegistration: false,
    mobileAtRegistrationRequired: false,
    memberDataKeyAtRegistration: false,
    memberDataKeyAtRegistrationRequired: false,
    companyDataKeyAtRegistration: false,
    companyDataKeyAtRegistrationRequired: false,
    memberDataKeyLabel: "",
    memberDataKeyDescription: "",
    companyDataKeyLabel: "",
    companyDataKeyDescription: "",
    memberPhotoIdLabel: "",
    memberPhotoIdDescription: "",
    memberPhotoIdAtRegistration: false,
    memberPhotoIdAtRegistrationRequired: false,
    companyAtRegistrationLabel: "",
    companyRegistrationGroupLabel: "",
  }),
  computed: {
    programId() {
      return this.$store.getters.programId;
    },
    isIncentableAdmin() {
      return this.$store.getters.isIncentableAdmin;
    },
    rewardCatalogues() {
      return this.$store.state.rewardcatalogue.rewardCatalogues;
    },
    loading() {
      return this.$store.getters.loading;
    },
    companyTags() {
      return this.$store.state.companytag.companyTags;
    },
  },
  methods: {
    handleSave() {
      const payload = {
        registrationOpen: this.registrationOpen || false,
        signinOpen: this.signinOpen || false,
        addCompanyAtRegistration: this.addCompanyAtRegistration || false,
        companyAtRegistrationRequired:
          this.companyAtRegistrationRequired || false,
        joinCompanyAtRegistration: this.joinCompanyAtRegistration || false,
        multipleCompaniesAtRegistration:
          this.multipleCompaniesAtRegistration || false,
        companyRegistrationGroups: this.companyRegistrationGroups || false,
        mobileAtRegistration: this.mobileAtRegistration || false,
        mobileAtRegistrationRequired:
          this.mobileAtRegistrationRequired || false,
        phoneAtRegistration: this.phoneAtRegistration || false,
        phoneAtRegistrationRequired: this.phoneAtRegistrationRequired || false,
        memberDataKeyAtRegistration: this.memberDataKeyAtRegistration || false,
        memberDataKeyAtRegistrationRequired:
          this.memberDataKeyAtRegistrationRequired || false,
        companyDataKeyAtRegistration:
          this.companyDataKeyAtRegistration || false,
        companyDataKeyAtRegistrationRequired:
          this.companyDataKeyAtRegistrationRequired || false,
        companyDataKeyLabel: this.companyDataKeyLabel || "",
        companyDataKeyDescription: this.companyDataKeyDescription || "",
        memberDataKeyLabel: this.memberDataKeyLabel || "",
        memberDataKeyDescription: this.memberDataKeyDescription || "",
        memberPhotoIdLabel: this.memberPhotoIdLabel || "",
        memberPhotoIdDescription: this.memberPhotoIdDescription || "",
        memberPhotoIdAtRegistration: this.memberPhotoIdAtRegistration || "",
        memberPhotoIdAtRegistrationRequired:
          this.memberPhotoIdAtRegistrationRequired || "",
        companyAtRegistrationLabel: this.companyAtRegistrationLabel || "",
        companyRegistrationGroupLabel: this.companyRegistrationGroupLabel || "",
      };

      this.$store.dispatch("setLoading", true);
      this.$store
        .dispatch("updateCurrentProgram", payload)
        .then(() => {
          this.$store.dispatch("setLoading", false);
        })
        .catch(() => {
          this.$store.dispatch("setLoading", false);
        });
    },
    clearDate() {
      this.billingRenewDate = "";
    },
  },
};
</script>

<style scoped></style>
