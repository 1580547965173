/* eslint-disable no-console */
<template>
  <div class="mt-3 mr-0 mb-8">
    <v-row no-gutters align="center">
      <v-col sm="6">
        <v-text-field
          v-model="search"
          solo-inverted
          dense
          flat
          hide-details
          label="Search"
          prepend-inner-icon="search"
          class="mr-4"
        ></v-text-field>
      </v-col>

      <v-col>
        <v-btn
          @click="handleOpenImportDialog()"
          v-if="options.upload"
          class="primary--text"
          text
          >Upload</v-btn
        >
        <v-btn
          v-if="options.download"
          class="primary--text"
          text
          download="Filename.csv"
          >Download</v-btn
        >
        <v-btn v-if="options.more" class="primary--text" text>More</v-btn>
      </v-col>

      <v-col sm="auto">
        <v-btn @click="handleNew()" color="primary" elevation="0">
          <v-icon>add</v-icon>new
        </v-btn>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="validationDataSets"
      :search="search"
      :options.sync="options"
      :loading="loadingTable"
      no-data-text="No Records..."
    >
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu bottom left close-on-content-click>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon>
              <v-icon>more_vert</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item link @click="handleEdit(item)">
              <v-list-item-title> Edit </v-list-item-title>
            </v-list-item>
            <v-list-item
              link
              :disabled="!isRoleSatisfied('Editor') || item.hasData"
              @click="handleDeleteConfirmation(item)"
            >
              <v-list-item-title>Delete </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>

      <template v-slot:item.tag="{ item }">
        <div
          @click="handleEdit(item)"
          class="primary--text"
          style="cursor: pointer"
        >
          {{ item.title }}
        </div>
      </template>
      <template v-slot:item.hasData="{ item }">
        <v-icon v-if="item.hasData" color="green">check_circle</v-icon>
      </template>
    </v-data-table>

    <validation-data-set-add-or-edit
      :validationDataSetId="selectedValidationDataSetId"
      :open="dialogNewOrEditOpen"
      @onClose="handleCloseNewOrEditDialog"
    />

    <!-- Delete dialog -->

    <v-dialog v-model="dialogDeleteOpen" max-width="400px" persistent>
      <v-card>
        <v-row justify="center" no-gutters>
          <v-card-title class="page-heading mb-6"> Are You Sure? </v-card-title>
        </v-row>

        <v-card-text class="px-16 pb-9">
          <v-row dense>
            <v-col align="center">
              You are about to delete
              <span class="incentable-form-bold">{{ title }}</span>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="handleCloseDeleteDialog()">Cancel</v-btn>
          <v-btn
            class="white--text"
            color="red"
            @click="handleDelete()"
            :loading="loading"
            elevation="0"
            :disabled="demo"
            >Confirm Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ValidationDataSetAddOrEdit from "./ValidationDataSetAddOrEdit.vue";
export default {
  created() {
    this.$store.dispatch("loadValidationDataSets");
  },
  components: { ValidationDataSetAddOrEdit },
  data: () => ({
    search: "",
    title: "",
    hasData: false,
    selectedValidationDataSetId: "",

    // Table columns
    headers: [
      { text: "Preset Group", value: "dataSetTitle", align: "left" },
      {
        text: "Has records",
        value: "hasData",
        align: "center",
        sortable: false,
      },
      { text: "Actions", value: "actions", align: "center", sortable: false },
    ],

    // Component settings common to all components
    dialogNewOrEditOpen: false,
    dialogDeleteOpen: false,

    // Table settings
    options: {
      sortBy: [],
      sortDesc: [false],
      itemsPerPage: 10,
      sortable: true,
      multiSort: true,
      search: true,
      filter: false,
      upload: false,
      download: false,
      more: false,
    },
  }),
  computed: {
    validationDataSets() {
      return this.$store.getters.validationDataSets;
    },
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    loadingTable() {
      return this.$store.getters.loadingTable;
    },
    loading() {
      return this.$store.getters.loading;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
  },

  methods: {
    handleNew() {
      // Open New form
      this.selectedValidationDataSetId = "";
      this.dialogNewOrEditOpen = true;
    },
    handleEdit(item) {
      this.$store.dispatch("loadValidationRecords", item.id);
      this.selectedValidationDataSetId = item.id;
      this.dialogNewOrEditOpen = true;
    },
    handleCloseNewOrEditDialog() {
      // Close New Or Edit form
      this.selectedValidationDataSetId = "";
      this.dialogNewOrEditOpen = false;
    },
    handleDeleteConfirmation(item) {
      // Delete record
      this.selectedValidationDataSetId = item.id;
      this.title = item.title;
      this.dialogDeleteOpen = true;
    },
    handleDelete() {
      // Delete record
      this.$store.dispatch("setLoading", true);
      this.$store
        .dispatch("deleteValidationDataSet", this.selectedValidationDataSetId)
        .then(() => (this.dialogDeleteOpen = false))
        .catch(() => (this.dialogDeleteOpen = false));
      this.$store.dispatch("setLoading", false);
    },
    handleCloseDeleteDialog() {
      // Close delete form
      this.selectedValidationDataSetId = "";
      this.dialogDeleteOpen = false;
    },
    async checkForRecords(dataSetId) {
      try {
        const hasRecords = await this.$store.dispatch(
          "dataSetHasRecords",
          dataSetId
        );
        console.log("result", hasRecords);
        if (hasRecords === true) {
          return "y";
        } else {
          return "n";
        }
      } catch (error) {
        console.error("Error checking for records:", error);
      }
    },
  },
};
</script>

<style></style>
