/* eslint-disable no-console */
import { db, timestamp } from "@/firebase";

const state = {
  offers: [],
  offerId: "",
  offerIndex: "",
  currentOffer: null,
  loadingCurrentOffer: false,
  pendingOfferUpdates: {},
  deletedEntries: [],
  processingOffer: false,
};

const actions = {
  async updateOffer({ commit, getters, state, dispatch }) {
    const { currentOffer, deletedEntries } = state;
    let databucketIdList = [];

    const clientNow = new Date();
    commit("setLoading", true);
    commit("setOfferExists", false);
    const titleInput = currentOffer.titleUppercase;
    const entriesInput = currentOffer.entries || [];
    const updatedInput = currentOffer.updated;
    const id = currentOffer.id;
    var offerRecord = db
      .collection("programs")
      .doc(getters.programId)
      .collection("offers");
    try {
      await dispatch("loadDatabuckets");
      const doc = await offerRecord.doc(id).get();
      const storedTitle = doc.data().titleUppercase;
      const storedEntries = doc.data().entries || [];
      const storedUpdatedTime = doc.data().updated;

      let resultEntries = [];
      // check if offer updated from other part
      if (storedUpdatedTime.toDate().getTime() === updatedInput.getTime()) {
        resultEntries = [...entriesInput];
      } else {
        const missedEntries = storedEntries.filter(
          (storedEntry) =>
            entriesInput.findIndex(
              (entryInput) => entryInput.id === storedEntry.id
            ) === -1 &&
            deletedEntries.findIndex(
              (delEntry) => delEntry.id === storedEntry.id
            ) === -1
        );
        resultEntries = [...entriesInput, ...missedEntries];
      }
      const offer = {
        ...currentOffer,
        updated: timestamp,
        entries: resultEntries,
      };
      // console.log(offer)
      // check if the title has been changed
      if (storedTitle === titleInput) {
        // If the title has not been changed, update the offer db record
        await offerRecord.doc(id).update(offer);
        const entries = currentOffer.entries || [];
        const request = entries.map(async (entry) => {
          const entryId = entry.id;
          const databuckets = getters.databuckets;
          const opportunity = currentOffer.opportunities.find(
            (el) => el.id === entry.opportunity
          );
          const dataBucketId = opportunity.databucket;
          let currentOffers =
            databuckets.find((el) => el.id === dataBucketId).offers || [];
          const targetCode = opportunity.targetCode;
          const points = entry.totalPoints;
          const member = entry.member;
          const company = entry.company || ""; // added 6 sep 2022
          const payload = {
            targetCode,
            points,
            member,
            company,
            entryId,
          };
          let redeemable = null;
          const index = currentOffers.findIndex((el) => el.entryId === entryId);
          if (index === -1) {
            redeemable = {
              type: "add",
              opportunity,
              transaction: payload,
              previousTransaction: null,
            };
            currentOffers.push(payload);
          } else {
            const previousTransaction = currentOffers.find(
              (el) => el.entryId === entryId
            );
            redeemable = {
              type: "update",
              opportunity,
              transaction: payload,
              previousTransaction,
            };
            currentOffers[index] = payload;
          }
          await dispatch("handleRedeemablePoints", redeemable);
          await db
            .collection("programs")
            .doc(getters.programId)
            .collection("databuckets")
            .doc(dataBucketId)
            .update({
              offers: currentOffers,
            });
          if (databucketIdList.indexOf(dataBucketId) === -1) {
            databucketIdList.push(dataBucketId);
          }
        });
        await Promise.all(request);
        // check deleted entries
        const delRequests = state.deletedEntries.map(async (entry) => {
          // console.log(entry);
          const entryId = entry.id;
          const databuckets = getters.databuckets;
          // console.log(currentOffer);
          const opportunity = currentOffer.opportunities.find(
            (el) => el.id === entry.opportunity
          );
          // console.log(opportunity);
          const dataBucketId = opportunity.databucket;
          // console.log(databuckets, dataBucketId);
          let currentOffers =
            databuckets.find((el) => el.id === dataBucketId).offers || [];
          const offers = currentOffers.filter((el) => el.entryId !== entryId);
          // console.log(currentOffers)
          // console.log(offers)
          await db
            .collection("programs")
            .doc(getters.programId)
            .collection("databuckets")
            .doc(dataBucketId)
            .update({
              offers,
            });
          if (databucketIdList.indexOf(dataBucketId) === -1) {
            databucketIdList.push(dataBucketId);
          }
        });
        await Promise.all(delRequests);
        dispatch("loadDatabuckets");
        commit("patchCurrentOffer", {
          updated: clientNow,
        });
      } else {
        // if the title has been changed, check to see if it exists elsewehere in the db. If not update the offer record
        const querySnapshot = await offerRecord
          .where("titleUppercase", "==", titleInput)
          .get();
        if (querySnapshot.size > 0) {
          // If offer is already registered...
          commit("setLoading", false);
          commit("setSnackbar", "Offer with same title already exists!");
          return;
        } else {
          // If offer title is unique...
          await offerRecord.doc(id).update(offer);
          const entries = currentOffer.entries || [];

          const request = entries.map(async (entry) => {
            const entryId = entry.id;
            const databuckets = getters.databuckets;
            const opportunity = currentOffer.opportunities.find(
              (el) => el.id === entry.opportunity
            );
            const dataBucketId = opportunity.databucket;
            let currentOffers =
              databuckets.find((el) => el.id === dataBucketId).offers || [];
            const targetCode = opportunity.targetCode;
            const points = entry.totalPoints;
            const member = entry.member;
            const company = entry.company;
            const payload = {
              targetCode,
              points,
              member,
              company,
              entryId,
            };
            const index = currentOffers.findIndex(
              (el) => el.entryId === entryId
            );
            if (index === -1) {
              currentOffers.push(payload);
            } else {
              currentOffers[index] = payload;
            }
            await db
              .collection("programs")
              .doc(getters.programId)
              .collection("databuckets")
              .doc(dataBucketId)
              .update({
                offers: currentOffers,
              });
            if (databucketIdList.indexOf(dataBucketId) === -1) {
              databucketIdList.push(dataBucketId);
            }
          });
          await Promise.all(request);

          // check deleted entries
          const delRequests = state.deletedEntries.map(async (entry) => {
            const entryId = entry.id;
            const databuckets = getters.databuckets;
            const opportunity = currentOffer.opportunities.find(
              (el) => el.id === entry.opportunity
            );
            const dataBucketId = opportunity.databucket;
            // console.log(databuckets, dataBucketId)
            let currentOffers =
              databuckets.find((el) => el.id === dataBucketId).offers || [];
            const offers = currentOffers.filter((el) => el.entryId !== entryId);
            // console.log(currentOffers)
            // console.log(offers)
            await db
              .collection("programs")
              .doc(getters.programId)
              .collection("databuckets")
              .doc(dataBucketId)
              .update({
                offers,
              });
            if (databucketIdList.indexOf(dataBucketId) === -1) {
              databucketIdList.push(dataBucketId);
            }
          });
          await Promise.all(delRequests);

          dispatch("loadDatabuckets");
          commit("patchCurrentOffer", {
            updated: clientNow,
          });
        }
      }
      commit("setLoading", false);
      commit("clearOfferPendingUpdates");

      commit("clearDeletedEntries");
      // console.log(databucketIdList);
      return databucketIdList ? databucketIdList : [];
    } catch (error) {
      console.log(error);
    }
  },

  handleRedeemablePoints({ dispatch }, payload) {
    if (!payload.opportunity.redeemable) {
      // console.log("not redeemable points", payload);
      return;
    }

    if (payload.type === "add") {
      // console.log("adding redeemable points : ", payload.transaction.points);
      const pointsTransaction = {
        points: payload.transaction.points,
        type: "Award",
        description: "Claim",
        notes: payload.opportunity.description,
        memberId: payload.transaction.member,
      };
      dispatch("addMemberPoints", pointsTransaction);
      return;
    }

    if (
      payload.type === "update" &&
      payload.previousTransaction.points === payload.transaction.points
    ) {
      // console.log("no change");
      return;
    }

    if (
      payload.type === "update" &&
      payload.previousTransaction.points !== payload.transaction.points
    ) {
      const change =
        payload.transaction.points - payload.previousTransaction.points;
      if (change > 0) {
        // console.log("adding redeemable points by : ", change);
        const pointsTransaction = {
          points: change,
          type: "Award",
          description: "Claim",
          notes: payload.opportunity.description,
          memberId: payload.transaction.member,
        };
        dispatch("addMemberPoints", pointsTransaction);
      } else if (change < 0) {
        // console.log("subtracting redeemable points by : ", change);
        const pointsTransaction = {
          points: change,
          type: "Award",
          description: "Claim",
          notes: payload.opportunity.description,
          memberId: payload.transaction.member,
        };
        dispatch("subtractMemberPoints", pointsTransaction);
      } else {
        return;
      }
      return;
    }
    return;
  },

  loadOffers({ commit, getters }) {
    commit("setLoadingCards", true);
    db.collection("programs")
      .doc(getters.programId)
      .collection("offers")
      .orderBy("titleUppercase")
      .get()
      .then(function (querySnapshot) {
        const offers = [];
        querySnapshot.forEach(function (doc) {
          offers.push({
            id: doc.id,
            title: doc.data().title,
            order: doc.data().order,
            displayTitle: doc.data().displayTitle,
            titleUppercase: doc.data().titleUppercase,
            status: doc.data().status,
            created: doc.data().created.toDate(),
            updated: doc.data().updated.toDate(),
            companyTags: doc.data().companyTags,
            memberTags: doc.data().memberTags,
          });
        });
        commit("setOffers", offers);
        commit("setLoadingCards", false);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  deleteOffer({ commit, getters, state }) {
    commit("setLoadingOffers", true);
    db.collection("programs")
      .doc(getters.programId)
      .collection("offers")
      .doc(state.currentOffer.id)
      .delete();
    commit("setLoadingOffers", false);
    commit("setSnackbar", "Offer deleted");
  },

  addOffer({ commit, getters }, payload) {
    commit("setLoading", true);
    commit("setOfferExists", false);
    const titleCheck = payload.titleUppercase;
    var collectionRef = db
      .collection("programs")
      .doc(getters.programId)
      .collection("offers");
    var query = collectionRef.where("titleUppercase", "==", titleCheck);
    query.get().then(function (querySnapshot) {
      if (querySnapshot.size > 0) {
        // If offer is already registered...
        commit("setOfferExists", true);
        commit("setLoading", false);
      } else {
        // If offer is not yet registered...
        const offer = {
          status: payload.status,
          title: payload.title,
          order: payload.order,
          displayTitle: payload.title,
          titleUppercase: payload.titleUppercase,
          created: payload.created,
          updated: payload.updated,
          companyTags: payload.companyTags,
          memberTags: payload.memberTags,
          createdBy: payload.createdBy,
          entries: [],
          opportunities: [],
          formFields: [],
        };
        db.collection("programs")
          .doc(getters.programId)
          .collection("offers")
          .add(offer)
          .then(function (docRef) {
            const key = docRef.id;
            commit("addOffer", { ...offer, id: key });
            commit("setDialogNewOffer", false);
            commit("setLoading", false);
            commit("setOfferExists", false);
            commit("setSnackbar", "Offer added");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  },

  async loadCurrentOffer({ commit, getters }, offerId) {
    commit("setLoadingCurrentOffer", true);
    commit("setCurrentOffer", null);
    commit("clearOfferPendingUpdates");

    const offerApiCall = db
      .collection("programs")
      .doc(getters.programId)
      .collection("offers")
      .doc(offerId)
      .get();

    let offerSnapshot;

    try {
      offerSnapshot = await offerApiCall;
    } catch (e) {
      throw "Error occured when fetching an offer.";
    }

    const offerData = offerSnapshot.data();

    if (!offerData) {
      throw "No such Offer";
    }

    const offer = {
      ...offerData,
      id: offerSnapshot.id,
      created: offerData.created.toDate(),
      updated: offerData.updated.toDate(),
    };
    // console.log(offer)
    commit("setCurrentOffer", offer);
    commit("setBreadCrumbDetail", offer.title);
    commit("setLoadingCurrentOffer", false);
  },

  selectOffer({ commit }, payload) {
    commit("setOfferId", payload);
  },

  setOfferExists({ commit }, payload) {
    commit("setOfferExists", payload);
  },

  setDialogNewOffer({ commit }, payload) {
    commit("setDialogNewOffer", payload);
  },

  setDialogEditOffer({ commit }, payload) {
    commit("setDialogEditOffer", payload);
  },

  deleteEntry({ commit }, payload) {
    commit("deleteEntry", payload);
  },

  // Note: This is only updating the store, not persisting with db
  patchCurrentOffer({ commit }, payload) {
    commit("patchCurrentOffer", payload);
  },

  setProcessingOffer({ commit }, payload) {
    commit("setProcessingOffer", payload);
  },
};

const mutations = {
  setOffers(state, payload) {
    state.offers = payload;
  },

  setLoadingOffers(state, payload) {
    state.loadingOffers = payload;
  },

  setProcessingOffer(state, payload) {
    state.processingOffer = payload;
  },

  setCurrentOffer(state, payload) {
    state.currentOffer = payload;
  },

  setLoadingCurrentOffer(state, payload) {
    state.loadingCurrentOffer = payload;
  },

  addOffer(state, payload) {
    state.offers.push(payload);
  },

  setOfferId(state, payload) {
    state.offerId = payload.offerId;
    state.offerIndex = payload.index;
  },

  setDialogNewOffer(state, payload) {
    state.dialogNewOffer = payload;
  },

  setDialogEditOffer(state, payload) {
    state.dialogEditOffer = payload;
  },

  setOfferExists(state, payload) {
    state.offerExists = payload;
  },

  clearOfferPendingUpdates(state) {
    state.pendingOfferUpdates = {};
  },

  patchCurrentOffer(state, payload) {
    state.currentOffer = {
      ...state.currentOffer,
      ...payload,
    };

    state.pendingOfferUpdates = {
      ...state.pendingOfferUpdates,
      ...payload,
    };
  },

  deleteEntry(state, payload) {
    state.deletedEntries = [...state.deletedEntries, payload];
  },

  clearDeletedEntries(state) {
    state.deletedEntries = [];
  },
};

const getters = {
  offers(state) {
    return state.offers;
  },

  offerExists(state) {
    return state.offerExists;
  },

  offerId(state) {
    return state.offerId;
  },

  offerIndex(state) {
    return state.offerIndex;
  },

  dialogNewOffer(state) {
    return state.dialogNewOffer;
  },

  dialogEditOffer(state) {
    return state.dialogEditOffer;
  },

  loadedOffer(state) {
    return (offerId) => {
      return state.offers.find((offer) => {
        return offer.id === offerId;
      });
    };
  },

  hasOfferPendingUpdates(state) {
    return Object.keys(state.pendingOfferUpdates).length !== 0;
  },

  processingOffer(state) {
    return state.processingOffer;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
