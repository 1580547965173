<template>
  <v-dialog
    v-model="open"
    :max-width="validationDataSetId ? '1200px' : '400'"
    persistent
  >
    <v-card class="px-2">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row no-gutters>
          <v-card-title class="page-heading">
            {{ validationDataSetId ? "Edit " : "Add New " }}
            Form Data Set
          </v-card-title>
        </v-row>

        <v-card-text :class="validationDataSetId ? 'px-5 mt-n8' : 'px-9'">
          <v-row no-gutters>
            <v-col :cols="validationDataSetId ? '3' : '12'">
              <v-text-field
                v-model="dataSetTitle"
                label="Data Set Title"
                name="title"
                id="title"
                :rules="titleRules"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row no-gutters v-show="validationDataSetId">
            <v-col>
              <v-row no-gutters align="center">
                <v-col sm="3">
                  <v-text-field
                    v-model="search"
                    solo-inverted
                    dense
                    flat
                    hide-details
                    label="Search"
                    prepend-inner-icon="search"
                    class="mr-4"
                  ></v-text-field>
                </v-col>

                <v-col>
                  <v-btn
                    @click="handleOpenImportDialog()"
                    v-if="options.upload"
                    class="primary--text"
                    text
                    >CSV Upload</v-btn
                  >
                  <v-btn
                    v-if="options.download"
                    class="primary--text"
                    text
                    download="Filename.csv"
                    >Download</v-btn
                  >
                  <v-btn v-if="options.more" class="primary--text" text
                    >More</v-btn
                  >
                </v-col>

                <v-col sm="auto">
                  <v-btn
                    @click="handleNewRecord()"
                    color="primary"
                    elevation="0"
                    class="mt-n2"
                  >
                    <v-icon>add</v-icon>new
                  </v-btn>
                </v-col>
              </v-row>
              <v-data-table
                :headers="headers"
                :items="validationRecords"
                :search="search"
                :options.sync="options"
                :loading="loadingValidationRecords"
                no-data-text="No Records..."
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-menu bottom left close-on-content-click>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon>
                        <v-icon>more_vert</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item link @click="handleEditRecord(item)">
                        <v-list-item-title> Edit </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        link
                        :disabled="!isRoleSatisfied('Editor')"
                        @click="handleRecordDeleteConfirmation(item)"
                      >
                        <v-list-item-title>Delete </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>

                <template v-slot:item.description="{ item }">
                  <div>{{ item.description | truncate(50) }}</div>
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col v-if="errorMessage">
              <v-alert type="error" :value="true">
                {{ errorMessage }}
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="handleClose()">Close</v-btn>
          <v-btn
            :class="orgTheme.buttonTextColor"
            color="primary"
            @click="handleSave()"
            :loading="loadingValidationDataSets"
            elevation="0"
            :disabled="demo"
            >Save</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>

    <validation-record-add-or-edit
      :validationRecordId="selectedValidationRecordId"
      :dataSetId="validationDataSetId"
      :open="dialogRecordNewOrEditOpen"
      @onClose="handleCloseRecordNewOrEditDialog"
    ></validation-record-add-or-edit>

    <!-- Delete dialog -->

    <v-dialog v-model="dialogRecordDeleteOpen" max-width="400px" persistent>
      <v-card>
        <v-row justify="center" no-gutters>
          <v-card-title class="page-heading mb-6"> Are You Sure? </v-card-title>
        </v-row>

        <v-card-text class="px-16 pb-9">
          <v-row dense>
            <v-col align="center">
              You are about to delete
              <span class="incentable-form-bold">{{ identifier }}</span>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="handleCloseRecordDeleteDialog()">Cancel</v-btn>
          <v-btn
            class="white--text"
            color="red"
            @click="handleRecordDelete()"
            :loading="loading"
            elevation="0"
            :disabled="demo"
            >Confirm Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import ValidationRecordAddOrEdit from "./ValidationRecordAddOrEdit.vue";
export default {
  components: { ValidationRecordAddOrEdit },
  created() {
    this.$store.dispatch("loadValidationRecords", this.validationDataSetId);
  },
  props: ["open", "validationDataSetId"],
  data: () => ({
    selectedValidationRecordId: "",
    valid: true,
    titleRules: [(v) => !!v || "Title is required"],
    search: "",
    title: "",
    points: "",
    dataSetTitle: "",
    identifier: "",
    errorMessage: null,
    dialogRecordNewOrEditOpen: false,
    dialogRecordDeleteOpen: false,
    // Table settings
    options: {
      sortBy: ["value"],
      sortDesc: [false],
      itemsPerPage: 5,
      sortable: true,
      multiSort: true,
      search: true,
      filter: false,
      upload: true,
      download: true,
      more: false,
    },
    headers: [
      { text: "Status", value: "status", align: "left" },
      { text: "Identifier", value: "identifier", align: "left" },
      { text: "Title", value: "title", align: "left" },
      { text: "Description", value: "description", align: "left" },
      { text: "Points", value: "points", align: "right" },
      { text: "Claims", value: "claims", align: "right" },
      { text: "Claims Limit", value: "claimsLimit", align: "right" },
      { text: "Actions", value: "actions", align: "center", sortable: false },
    ],
  }),

  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    error() {
      return this.$store.getters.error;
    },
    loading() {
      return this.$store.getters.loading;
    },
    loadingValidationRecord() {
      return this.$store.getters.loadingValidationRecord;
    },
    loadingValidationRecords() {
      return this.$store.getters.loadingValidationRecords;
    },
    loadingValidationDataSets() {
      return this.$store.getters.loadingValidationDataSets;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    validationDataSets() {
      return this.$store.getters.validationDataSets;
    },
    validationRecords() {
      if (!this.loadingValidationRecords) {
        return this.$store.getters.validationRecords;
      }
      return [];
    },
  },

  watch: {
    validationDataSetId: {
      handler(newVal) {
        if (newVal && this.open) {
          const selectedDataSet = this.validationDataSets.find(
            (item) => item.id === newVal
          );
          const { dataSetTitle } = selectedDataSet;
          this.dataSetTitle = dataSetTitle;
        }
      },
    },
  },

  methods: {
    handleNewRecord() {
      // Open New form
      this.selectedValidationRecordId = "";
      this.dialogRecordNewOrEditOpen = true;
    },
    handleEditRecord(item) {
      console.log(item);
      // Open Edit form
      this.selectedValidationRecordId = item.id;
      this.dialogRecordNewOrEditOpen = true;
    },
    handleCloseRecordNewOrEditDialog() {
      // Close New Or Edit form
      this.selectedValidationRecordId = "";
      this.dialogRecordNewOrEditOpen = false;
    },
    handleClose() {
      this.clear();
      this.$emit("onClose");
    },
    handleRecordDeleteConfirmation(item) {
      // Delete record
      this.selectedValidationRecordId = item.id;
      this.identifier = item.identifier;
      this.dialogRecordDeleteOpen = true;
    },
    async handleRecordDelete() {
      const payload = {
        id: this.selectedValidationRecordId,
        dataSetId: this.validationDataSetId,
      };
      this.$store
        .dispatch("deleteValidationRecord", payload)
        .then(() => (this.dialogRecordDeleteOpen = false))
        .catch(() => (this.dialogRecordDeleteOpen = false));
    },
    handleCloseRecordDeleteDialog() {
      // Close delete form
      this.selectedValidationRecordId = "";
      this.dialogRecordDeleteOpen = false;
    },

    clear() {
      this.errorMessage = null;
      this.dataSetTitle = "";
      this.$refs.form.resetValidation();
      this.$store.dispatch("setLoadingValidationRecord", false);
    },

    async handleSave() {
      await this.$refs.form.validate();
      if (!this.valid) {
        return;
      }

      const payload = {
        id: this.validationDataSetId || "",
        dataSetTitle: this.dataSetTitle || "",
        dataSetTitleUppercase: this.dataSetTitle.toUpperCase() || "",
      };

      const action = this.validationDataSetId
        ? "updateValidationDataSet"
        : "createValidationDataSet";

      this.$store
        .dispatch(action, payload)
        .then(() => {
          this.handleClose();
        })
        .catch((e) => {
          this.errorMessage = e;
          this.$store.dispatch("setLoadingValidationRecord", false);
        });
    },
  },
};
</script>

<style></style>
