<template>
  <div class="text-center">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :close-on-click="false"
      :nudge-width="300"
      offset-x
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          v-on="on"
          @click="onSettingsEdit"
          class="action-icon"
        >
          settings
        </v-icon>
      </template>

      <v-card class="pa-2">
        <v-row align="center" class="pt-3" no-gutters>
          <v-col cols="auto">
            <v-icon class="icon">{{ editingField.icon }}</v-icon>
          </v-col>
          <v-col>
            <span class="type-subheading">{{ editingField.title }}</span>
          </v-col>
        </v-row>

        <v-form ref="form" v-model="valid" lazy-validation>
          <v-list>
            <v-list-item v-if="showLabel">
              <v-text-field
                v-model="editingField.label"
                label="Label"
                :rules="labelRules"
              ></v-text-field>
            </v-list-item>

            <v-list-item v-if="showPlaceholder" class="pt-0 mt-n2">
              <v-text-field
                v-model="editingField.placeholder"
                label="Placeholder"
                clearable
              ></v-text-field>
            </v-list-item>

            <v-list-item v-if="showMin" class="pt-0 mt-n2">
              <v-text-field
                v-model="editingField.min"
                label="Min"
                :rules="numberRules"
                clearable
              ></v-text-field>
            </v-list-item>

            <v-list-item v-if="showMax" class="pt-0 mt-n2">
              <v-text-field
                v-model="editingField.max"
                label="Max"
                :rules="numberRules"
                clearable
              ></v-text-field>
            </v-list-item>

            <v-list-item v-if="showThumbLabel">
              <v-list-item-action>
                <v-switch
                  v-model="editingField.thumbLabel"
                  color="primary"
                ></v-switch>
              </v-list-item-action>
              <v-list-item-title>Thumb label</v-list-item-title>
            </v-list-item>

            <v-row v-if="showClearable || showRequired" no-gutters>
              <v-row no-gutters class="pl-3 mt-n3">
                <v-col v-if="showRequired" cols="6">
                  <v-row no-gutters align="center">
                    <v-switch
                      v-model="editingField.required"
                      color="primary"
                    ></v-switch>
                    <span class="ml-2">Mandatory</span>
                  </v-row>
                </v-col>
                <v-col v-if="showClearable" cols="6">
                  <v-row no-gutters align="center">
                    <v-switch
                      v-model="editingField.clearable"
                      color="primary"
                    ></v-switch>
                    <span class="ml-2">Clearable</span>
                  </v-row>
                </v-col>
              </v-row>
            </v-row>
          </v-list>

          <!-- Headings -->
          <div v-if="editingField.editor === 'headings'">
            <v-text-field v-model="editingField.styles.value"></v-text-field>
            <v-card elevation="0" class="editor">
              <HeadingEditor :hideSaveButton="true"></HeadingEditor>
            </v-card>
          </div>

          <!-- Presets -->
          <div v-if="selectionDataRequired" class="mt-3 mb-6">
            <div></div>
            <div class="form-heading ml-3">Field Presets</div>
            <div class="caption ml-3">
              Predefined options to populate the form field
            </div>
            <v-row class="mt-n3 pl-3" no-gutters>
              <v-radio-group
                v-model="editingField.selectionDataType"
                row
                @change="onRadioChange"
              >
                <v-radio label="Simple" value="static"></v-radio>
                <v-radio label="Advanced" value="dynamic"></v-radio>
              </v-radio-group>
            </v-row>

            <v-card
              v-if="editingField.selectionDataType === 'static'"
              elevation="0"
              outlined
              color="grey lighten-4"
              class="mx-2 mt-n3"
            >
              <form data-vv-scope="newFormOption">
                <v-row no-gutters class="mt-2 mx-2 mb-n6" align="center">
                  <v-col>
                    <v-text-field
                      v-model="newFormOption.value"
                      label="Add new option"
                      outlined
                      dense
                      v-validate="'required'"
                      name="formOption"
                      :error-messages="errors.collect('field.value')"
                      background-color="white"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn
                      :disabled="disableAdd"
                      @click="addFormOption('newFormOption')"
                      color="primary"
                      elevation="0"
                      class="mt-n6"
                      small
                      icon
                      ><v-icon>add</v-icon></v-btn
                    >
                  </v-col>
                </v-row>
              </form>

              <v-data-table
                class="grey lighten-4 mt-n6"
                :headers="headers"
                :items="editingField.presets.staticOptions"
                :search="search"
                :options.sync="options"
                :loading="loadingTable"
                no-data-text="No options yet..."
                :hide-default-footer="true"
                :disable-pagination="true"
                dense
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-menu bottom left close-on-content-click>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon>
                        <v-icon>more_vert</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item link @click="handleEditOptionOpen(item)">
                        <v-list-item-title> Edit </v-list-item-title>
                      </v-list-item>
                      <v-list-item link @click="handleDeleteOption(item)">
                        <v-list-item-title>Delete </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
            </v-card>

            <div v-else>
              <v-row no-gutters>
                <v-col class="ml-4 mr-2">
                  <v-select
                    v-model="editingField.selectedSelectionDataSet"
                    :items="validationDataSets"
                    item-text="dataSetTitle"
                    item-value="id"
                    clearable
                    v-validate="'required'"
                    :error-messages="errors.collect('field.dataset')"
                    name="dataset"
                    class="mt-n2"
                    @change="onSelectionDataChange"
                    label="Form Presets Group"
                  ></v-select>
                </v-col>
                <v-col cols="auto">
                  <v-btn
                    small
                    elevation="0"
                    class="mr-2"
                    color="primary"
                    text
                    :disabled="!editingField.selectedSelectionDataSet"
                    @click="
                      handleEditFormDataSet(
                        editingField.selectedSelectionDataSet
                      )
                    "
                    >edit</v-btn
                  >
                </v-col>
              </v-row>
              <v-row v-if="editingField" no-gutters class="ml-3 pr-3">
                <v-select
                  v-model="editingField.itemsToDisplay"
                  :items="itemsAvailableToDisplay"
                  label="Values To Display"
                  multiple
                  deletable-chips
                  chips
                  class="mt-n1"
                >
                  <template v-slot:selection="{ item, index, attrs }">
                    <v-chip
                      v-bind="attrs"
                      :key="index"
                      class="capitalize"
                      close
                      @click:close="
                        editingField.itemsToDisplay.splice(index, 1)
                      "
                    >
                      {{ item }}
                    </v-chip>
                  </template>
                  <template v-slot:item="{ item, attrs, on }">
                    <v-list-item v-bind="attrs" v-on="on" class="capitalize">
                      <v-list-item-action>
                        <v-checkbox
                          v-model="attrs.inputValue"
                          :ripple="false"
                        ></v-checkbox>
                      </v-list-item-action>
                      <v-list-item-content>
                        <v-list-item-title>{{ item }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                </v-select>
              </v-row>
            </div>
          </div>

          <!-- Validation -->
          <div v-if="validationDataRequired" class="mt-3 mb-2">
            <div class="form-heading ml-3">Validation</div>
            <div class="caption ml-3">
              Verify that entries match a preset value
            </div>

            <v-row class="mt-n2 pl-3" no-gutters>
              <v-radio-group
                v-model="editingField.validation.validationFormDataType"
                row
                @change="onRadioChange"
              >
                <v-radio label="None" value="none"></v-radio>
                <v-radio label="Simple" value="static"></v-radio>
                <v-radio label="Advanced" value="dynamic"></v-radio>
              </v-radio-group>
            </v-row>

            <v-card
              v-if="editingField.validation.validationFormDataType === 'static'"
              elevation="0"
              outlined
              color="grey lighten-4"
              class="mx-2 mt-0"
            >
              <form data-vv-scope="newFormOption">
                <v-row no-gutters class="mt-2 mx-2 mb-n6" align="center">
                  <v-col>
                    <v-text-field
                      v-model="newFormValidationOption.value"
                      label="Add new option"
                      outlined
                      dense
                      v-validate="'required'"
                      name="formOption"
                      :error-messages="errors.collect('field.value')"
                      background-color="white"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn
                      :disabled="disableAdd"
                      @click="
                        addFormValidationOption('newFormValidationOption')
                      "
                      color="primary"
                      elevation="0"
                      class="mt-n6"
                      small
                      icon
                      ><v-icon>add</v-icon></v-btn
                    >
                  </v-col>
                </v-row>
              </form>

              <v-data-table
                class="grey lighten-4 mt-n6 mb-6"
                :headers="headers"
                :items="editingField.staticValidationOptions"
                :search="search"
                :options.sync="options"
                :loading="loadingTable"
                no-data-text="No options yet. Use the form above to enter them."
                :hide-default-footer="true"
                :disable-pagination="true"
                dense
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-menu bottom left close-on-content-click>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn v-bind="attrs" v-on="on" icon>
                        <v-icon>more_vert</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        link
                        @click="handleEditValidationOptionOpen(item)"
                      >
                        <v-list-item-title> Edit </v-list-item-title>
                      </v-list-item>
                      <v-list-item
                        link
                        @click="handleDeleteValidationOption(item)"
                      >
                        <v-list-item-title>Delete </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>
            </v-card>

            <div
              v-if="
                editingField.validation.validationFormDataType === 'dynamic'
              "
            >
              <v-row no-gutters class="mx-3 mt-n4">
                <v-select
                  v-model="
                    editingField.validation.selectedValidationPresetGroup
                  "
                  :items="validationDataSets"
                  item-text="dataSetTitle"
                  item-value="id"
                  clearable
                  label="Form Presets Group"
                  v-validate="'required'"
                  :error-messages="errors.collect('field.dataset')"
                  name="dataset"
                  @change="onSelectionDataChange"
                ></v-select>
              </v-row>
            </div>
          </div>

          <!-- Entry limits -->
          <div v-if="showEntryLimits">
            <div class="form-heading ml-3 mt-4 mb-n1">Entry Limit</div>
            <div class="caption ml-3">
              Sets the maximum number of times a unique value can be submitted
            </div>
            <v-text-field
              class="mx-3"
              label="Maximum Entries Per Value"
              v-model="editingField.entryLimits.maximumEntriesPerValue"
              :rules="limitRules"
              hint="Leave blank for unlimited"
              persistent-hint
              clearable
            ></v-text-field>
          </div>
        </v-form>

        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>

          <v-btn text @click="handleCancel"> Cancel </v-btn>
          <v-btn
            color="primary"
            @click="handleSave('field')"
            elevation="0"
            small
          >
            submit
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>

    <EditOption
      v-if="editOptionDialogOpen"
      :editingOption="editingOption"
      :open="editOptionDialogOpen"
      @onCancelEditOption="handleEditOptionClose"
      @onSaveEditOption="handleEditOptionSave"
    >
    </EditOption>

    <EditValidationOption
      v-if="editValidationOptionDialogOpen"
      :editingOption="editingValidationOption"
      :open="editValidationOptionDialogOpen"
      @onCancelEditOption="handleEditValidationOptionClose"
      @onSaveEditOption="handleEditValidationOptionSave"
    >
    </EditValidationOption>

    <validation-data-set-add-or-edit
      :validationDataSetId="selectedValidationDataSetId"
      :open="dialogNewOrEditOpen"
      @onClose="handleCloseNewOrEditDialog"
    />
  </div>
</template>

<script>
import EditOption from "./EditOption.vue";
import EditValidationOption from "./EditValidationOption.vue";
import HeadingEditor from "../../../../Design/Editor/Shared/HeadingEditor.vue";
import ValidationDataSetAddOrEdit from "../../ValidationData/ValidationDataSetAddOrEdit.vue";
export default {
  $_veeValidate: {
    validator: "new",
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
  },
  components: {
    EditOption,
    HeadingEditor,
    EditValidationOption,
    ValidationDataSetAddOrEdit,
  },
  mounted() {
    this.$validator.localize("en", this.dictionary);
  },
  created() {
    this.editingField = Object.assign({}, this.field);
  },
  data: () => ({
    fav: true,
    message: false,
    hints: true,
    menu: false,
    editingField: {},
    editedIndex: -1,
    editingOption: {},
    editOptionDialogOpen: false,
    newFormOption: {
      value: "",
    },
    editingValidationOption: {},
    editValidationOptionDialogOpen: false,
    newFormValidationOption: {
      value: "",
    },
    valid: true,
    numberRules: [
      (v) => !!v || "Number is required",
      (v) => !isNaN(v) || "Input must be a number",
    ],
    limitRules: [(v) => !isNaN(v) || "Input must be a number"],
    labelRules: [(v) => !!v || "Label is required"],
    headers: [
      { text: "Option", value: "value", align: "left" },
      { text: "Actions", value: "actions", align: "center", sortable: false },
    ],
    search: "",
    dictionary: {
      custom: {
        label: {
          required: () => "Label is required",
        },
        value: {
          required: () => "Data option is required",
        },
        dataset: {
          required: () => "Data set is required",
        },
      },
    },
    options: {
      sortBy: ["title"],
      sortDesc: [false],
      sortable: true,
      multiSort: true,
      search: true,
      filter: false,
      upload: false,
      download: false,
      more: false,
    },
    dialogNewOrEditOpen: false,
    selectedValidationDataSetId: "",
    itemsAvailableToDisplay: ["identifier", "title", "description", "points"],
  }),

  computed: {
    currentOffer() {
      return this.$store.state.offer.currentOffer;
    },
    localFormOptions() {
      return this.editingField.presets.staticOptions;
    },
    showMin() {
      return Object.prototype.hasOwnProperty.call(this.editingField, "min");
    },
    showMax() {
      return Object.prototype.hasOwnProperty.call(this.editingField, "max");
    },
    showThumbLabel() {
      return Object.prototype.hasOwnProperty.call(
        this.editingField,
        "thumbLabel"
      );
    },
    showClearable() {
      return Object.prototype.hasOwnProperty.call(
        this.editingField,
        "clearable"
      );
    },
    showRequired() {
      return Object.prototype.hasOwnProperty.call(
        this.editingField,
        "required"
      );
    },
    showPlaceholder() {
      return Object.prototype.hasOwnProperty.call(
        this.editingField,
        "placeholder"
      );
    },
    showLabel() {
      if (this.editingField.editor === "headings") {
        return false;
      } else {
        return true;
      }
    },
    selectionDataRequired() {
      return Object.prototype.hasOwnProperty.call(
        this.editingField,
        "selectionDataType"
      );
    },
    validationDataRequired() {
      return Object.prototype.hasOwnProperty.call(
        this.editingField.validation,
        "validationFormDataType"
      );
    },
    showEntryLimits() {
      return Object.prototype.hasOwnProperty.call(
        this.editingField,
        "entryLimits"
      );
    },
    formOptions() {
      if (this.editingField.selectionDataType === "dynamic") {
        return this.dynamicFormOptions;
      } else {
        return this.editingField.presets.staticOptions.map((el) => ({
          identifier: el.value,
          title: el.value,
        }));
      }
    },
    loadingTable() {
      return this.$store.getters.loadingTable;
    },
    disableAdd() {
      return this.newFormOption.formOption === "";
    },
    validationDataSets() {
      return this.$store.getters.validationDataSets;
    },
    validationRecords() {
      return this.$store.getters.validationRecords;
    },
    loadingValidationRecords() {
      return this.$store.getters.loadingValidationRecords;
    },
  },
  methods: {
    loadValData() {
      this.$store.dispatch("loadValidationDataSets");
    },
    handleCancel() {
      this.$emit("onSettingsEdit", false);
    },
    handleSave() {
      this.validate();
      if (!this.valid) {
        console.log("invalid");
        return;
      }
      this.$emit("onUpdateSettings", this.editingField);
      this.$emit("onSettingsEdit", false);
    },
    async onSettingsEdit() {
      if (this.editingField.editor === "headings") {
        await this.$store.dispatch("editingField", this.editingField.styles);
      }
      this.$emit("onSettingsEdit", true);
    },
    onRadioChange() {
      this.selectedCheckboxes = [];
      this.selectedRadio = null;
      this.selectedDropdown = null;
      this.checkDynamicSelected();
    },
    checkDynamicSelected() {
      if (this.editingField.selectionDataType === "dynamic") {
        this.$store.dispatch("loadValidationDataSets");
      }
    },
    async onSelectionDataChange() {
      this.dynamicFormOptions = await this.$store.dispatch(
        "getValidationRecords",
        this.editingField.selectedSelectionDataSet
      );
    },
    addFormOption(scope) {
      this.$validator.validateAll(scope).then((validationResult) => {
        const formOption = {
          ...this.newFormOption,
        };
        if (!validationResult) {
          return;
        }
        const newOptions = [...this.editingField.presets.staticOptions];
        newOptions.push(formOption);
        this.editingField.presets.staticOptions = newOptions;
        this.resetNewFormOption();
      });
    },
    addFormValidationOption(scope) {
      this.$validator.validateAll(scope).then((validationResult) => {
        const formOption = {
          ...this.newFormValidationOption,
        };
        if (!validationResult) {
          return;
        }
        const newOptions = [...this.editingField.staticValidationOptions];
        newOptions.push(formOption);
        this.editingField.staticValidationOptions = newOptions;
        this.resetNewFormValidationOption();
      });
    },
    resetNewFormOption() {
      this.newFormOption = {
        value: "",
      };
      this.$validator.reset();
    },
    resetNewFormValidationOption() {
      this.newFormValidationOption = {
        value: "",
      };
      this.$validator.reset();
    },
    handleDeleteOption(item) {
      this.editedIndex = this.editingField.presets.staticOptions.indexOf(item);
      const newOptions = [...this.editingField.presets.staticOptions];
      newOptions.splice(this.editedIndex, 1);
      this.editingField.presets.staticOptions = newOptions;
      this.editedIndex = -1;
    },
    handleDeleteValidationOption(item) {
      this.editedIndex =
        this.editingField.staticValidationOptions.indexOf(item);
      const newOptions = [...this.editingField.staticValidationOptions];
      newOptions.splice(this.editedIndex, 1);
      this.editingField.staticValidationOptions = newOptions;
      this.editedIndex = -1;
    },
    handleEditOptionOpen(item) {
      this.editedIndex = this.editingField.presets.staticOptions.indexOf(item);
      this.editingOption =
        this.editingField.presets.staticOptions[this.editedIndex];
      this.editOptionDialogOpen = true;
    },
    handleEditValidationOptionOpen(item) {
      this.editedIndex =
        this.editingField.staticValidationOptions.indexOf(item);
      this.editingValidationOption =
        this.editingField.staticValidationOptions[this.editedIndex];
      this.editValidationOptionDialogOpen = true;
    },
    handleEditOptionClose() {
      this.editOptionDialogOpen = false;
      this.editingOption = {};
      this.editedIndex = -1;
    },
    handleEditValidationOptionClose() {
      this.editValidationOptionDialogOpen = false;
      this.editingValidationOption = {};
      this.editedIndex = -1;
    },
    handleEditOptionSave(item) {
      // Create a new array to trigger reactivity
      const newOptions = [...this.editingField.presets.staticOptions];
      newOptions[this.editedIndex] = item;
      this.editingField.presets.staticOptions = newOptions;
      this.editOptionDialogOpen = false;
      this.editingOption = {};
      this.editedIndex = -1;
    },
    handleEditValidationOptionSave(item) {
      // Create a new array to trigger reactivity
      const newOptions = [...this.editingField.staticValidationOptions];
      newOptions[this.editedIndex] = item;
      this.editingField.staticValidationOptions = newOptions;
      this.editValidationOptionDialogOpen = false;
      this.editingValidationOption = {};
      this.editedIndex = -1;
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    handleEditFormDataSet(id) {
      this.$store.dispatch("loadValidationRecords", id);
      this.selectedValidationDataSetId = id;
      this.dialogNewOrEditOpen = true;
    },
    handleCloseNewOrEditDialog() {
      this.selectedValidationDataSetId = "";
      this.dialogNewOrEditOpen = false;
    },
  },
};
</script>

<style scoped>
.action-icon {
  color: #ffffff !important;
  font-size: 20px !important;
  margin: 3px;
}
.icon {
  color: #505050 !important;
  margin-left: 14px;
}
.type-subheading {
  font-weight: 300 !important;
  font-size: 13px !important;
  font-family: "Roboto", sans-serif !important;
  color: #505050 !important;
  margin-left: 6px;
}
.editor {
  width: 360px;
  margin-left: 20px;
  margin-right: 20px;
}
</style>
