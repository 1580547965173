<template>
  <div class="px-4 pt-0 mb-8">
    <v-row no-gutters justify="center">
      <v-col cols="12">
        <v-row no-gutters class="incentable-form-heading mt-6"
          >Credit Card Payments</v-row
        >
        <subscriptions-table
          :subscriptions="subscriptions"
          class="my-6"
        ></subscriptions-table>
        <v-row no-gutters class="incentable-form-heading mt-6"
          >Bank Transfer Payments</v-row
        >
        <subscriptions-table-manual
          :subscriptions="manualSubscriptions"
          class="my-6"
        ></subscriptions-table-manual>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import SubscriptionsTable from "../Subscription/SubscriptionsTable.vue";
import SubscriptionsTableManual from "../Subscription/SubscriptionsTableManual.vue";
export default {
  components: { SubscriptionsTable, SubscriptionsTableManual },
  data: () => ({
    dialogDelete: false,
  }),
  computed: {
    loading() {
      return this.$store.getters.loading;
    },
    programId() {
      return this.$store.getters.programId;
    },
    currentProgram() {
      return this.$store.state.program.currentProgram;
    },
    isIncentableAdmin() {
      return this.$store.getters.isIncentableAdmin;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    subscriptions() {
      const arr = this.$store.state.plans.subscriptions;
      return arr.filter((el) => el.program === this.programId);
    },
    manualSubscriptions() {
      return this.$store.getters.manualSubscriptions;
    },
  },
  watch: {},
  created() {
    this.$store.dispatch("loadSubscriptions"),
      this.$store.dispatch("loadManualSubscriptions", this.programId),
      this.$store.dispatch("loadProgramsQuickList");
  },
  methods: {},
};
</script>

<style scoped>
.no-padding {
  padding: 0px !important;
  padding-top: 24px !important;
}
</style>
