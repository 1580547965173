<template>
  <div class="px-4 pt-4 mb-8">
    <v-row no-gutters class="incentable-form-heading mb-2"> Sandbox </v-row>
    <ScratchCard />
  </div>
</template>

<script>
import ScratchCard from "./SandboxComponents/ScratchCardV1.vue";
export default {
  components: {
    ScratchCard,
  },
  created() {},
  data: () => ({}),
  computed: {},
  methods: {},
  watch: {},
};
</script>

<style scoped></style>
