<template>
  <div>
    <v-row no-gutters justify="start">
      <v-col cols="12">
        <v-row no-gutters>
          <v-col>
            <v-tabs v-model="tab">
              <v-tab class="tab" @click="clearSearch()">Data Buckets</v-tab>
              <v-tab class="tab" @click="clearSearch()">Inactive</v-tab>
              <v-tab class="tab" @click="clearSearch()">Archive</v-tab>
            </v-tabs>
          </v-col>
          <v-col cols="12" sm="4">
            <v-text-field
              class="mt-3 mr-3 caption"
              v-model="search"
              solo-inverted
              dense
              flat
              hide-details
              prepend-inner-icon="search"
              clearable
              @click:clear="clearSearch()"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row no-gutters justify="start" class="px-1">
      <v-col cols="12">
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <loading-cards v-if="loadingCards" :count="cardCount" />
            <databuckets-list
              :databuckets="filteredDatabuckets"
              status="Active"
            ></databuckets-list>
          </v-tab-item>
          <v-tab-item>
            <loading-cards v-if="loadingCards" :count="cardCount" />
            <databuckets-list
              :databuckets="filteredDatabuckets"
              status="Inactive"
            ></databuckets-list>
          </v-tab-item>
          <v-tab-item>
            <loading-cards v-if="loadingCards" :count="cardCount" />
            <databuckets-list
              :databuckets="filteredDatabuckets"
              status="Archive"
            ></databuckets-list>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <!-- Loading Cards Table-->
  </div>
</template>
<script>
import DatabucketsList from "./List.vue";
export default {
  components: {
    DatabucketsList,
  },
  created() {
    this.$store.dispatch("initialize");
    this.$store.dispatch("loadDatabuckets");
    this.$store.dispatch("setNavMenu", true);
  },
  data: () => ({
    search: null,
    tab: null,
  }),
  computed: {
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    databuckets() {
      return this.$store.state.databuckets.databuckets;
    },
    programId() {
      return this.$store.getters.programId;
    },
    cardCount() {
      return 6;
    },
    loadingCards() {
      return this.$store.getters.loadingCards;
    },
    filteredDatabuckets() {
      const array = this.databuckets;
      if (this.search !== null) {
        var input = this.search.toUpperCase();
        var filtered = array.filter((item) =>
          item.titleUppercase.includes(input)
        );
        return filtered;
      }
      return array;
    },
  },
  methods: {
    clearSearch() {
      this.search = null;
    },
  },
};
</script>

<style scoped></style>
