<template>
  <v-avatar size="38" class="mr-2">
    <v-img :src="gravatarUrl" :alt="alt" :class="imgClass" />
  </v-avatar>
</template>

<script>
import md5 from "md5";

export default {
  props: {
    email: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      default: 100, // Default size (in pixels)
    },
    defaultAvatar: {
      type: String,
      default: "identicon", // Options: "mp", "identicon", "monsterid", "wavatar", "retro", "robohash"
    },
    alt: {
      type: String,
      default: "User Avatar",
    },
    imgClass: {
      type: String,
      default: "rounded-full", // Tailwind style (optional)
    },
  },
  computed: {
    gravatarUrl() {
      const emailHash = md5(this.email.trim().toLowerCase());
      return `https://www.gravatar.com/avatar/${emailHash}?s=${this.size}&d=${this.defaultAvatar}`;
    },
  },
};
</script>
