<template>
  <div>
    <v-row no-gutters justify="center" class="incentable-heading"
      >Scratch & Win!</v-row
    >
    <v-row no-gutters justify="center">
      <div class="scratch-card-container">
        <canvas
          class="scratch-card"
          ref="scratchCanvas"
          :width="canvasWidth"
          :height="canvasHeight"
          @mousedown="startScratching"
          @mousemove="scratch"
          @mouseup="stopScratching"
          @mouseleave="stopScratching"
          @touchstart="startScratching"
          @touchmove="scratch"
          @touchend="stopScratching"
        ></canvas>
        <img
          :src="imageSrc"
          alt="Background"
          class="background-image"
          :width="canvasWidth"
          :height="canvasHeight"
        />
      </div>
    </v-row>
    <div v-if="isRevealed">
      <v-row no-gutters justify="center" class="incentable-heading">
        🎉 You won! 🎉
      </v-row>
      <v-row no-gutters justify="center"
        ><v-btn @click="playAgain">Reset scratch card</v-btn></v-row
      >
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      canvasWidth: 300,
      canvasHeight: 200,
      isScratching: false,
      isRevealed: false,
      revealThreshold: 0.6,
      ctx: null,
      backgroundImage: null,
      debugInfo: null,
      imageSrc: require("@/assets/Winner1000.png"),
    };
  },
  mounted() {
    this.loadBackgroundImage();
  },
  methods: {
    loadBackgroundImage() {
      const bgImg = new Image();
      bgImg.crossOrigin = "anonymous"; // Prevent cross-origin issues
      bgImg.src = this.imageSrc;

      bgImg.onerror = (error) => {
        console.error("Image failed to load:", error);
        this.debugInfo = {
          imageLoaded: false,
          imageSrc: bgImg.src,
          imageDimensions: "N/A",
          error: error,
        };
      };

      bgImg.onload = () => {
        this.backgroundImage = bgImg;

        // Debug information
        this.debugInfo = {
          imageLoaded: true,
          imageSrc: bgImg.src,
          imageDimensions: `${bgImg.width}x${bgImg.height}`,
        };

        this.createScratchLayer();
      };
    },
    createScratchLayer() {
      const canvas = this.$refs.scratchCanvas;
      if (!canvas || !this.backgroundImage) {
        console.error("Canvas or image not ready");
        return;
      }

      const ctx = canvas.getContext("2d", { willReadFrequently: true });

      // Clear any previous content
      ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);

      // Draw background image
      ctx.drawImage(
        this.backgroundImage,
        0, // source x
        0, // source y
        this.backgroundImage.width, // source width
        this.backgroundImage.height, // source height
        0, // destination x
        0, // destination y
        this.canvasWidth, // destination width
        this.canvasHeight // destination height
      );

      // Create scratch overlay
      ctx.globalCompositeOperation = "source-over"; // Normal mode for drawing overlay
      ctx.fillStyle = "#CCCCCC"; // Fully opaque gray
      ctx.fillRect(0, 0, this.canvasWidth, this.canvasHeight);

      // Add optional text
      const text = "Scratch Here!";
      ctx.fillStyle = "#000";
      ctx.font = "16px Arial";
      const textWidth = ctx.measureText(text).width;
      ctx.fillText(
        text,
        (this.canvasWidth - textWidth) / 2,
        this.canvasHeight / 2
      );

      this.ctx = ctx;
    },
    startScratching(event) {
      this.isScratching = true;
      this.scratch(event);
    },
    scratch(event) {
      if (!this.isScratching || !this.ctx) return;

      const canvas = this.$refs.scratchCanvas;
      const rect = canvas.getBoundingClientRect();

      // Normalize mouse/touch positions
      const clientX = event.touches ? event.touches[0].clientX : event.clientX;
      const clientY = event.touches ? event.touches[0].clientY : event.clientY;

      const x = clientX - rect.left;
      const y = clientY - rect.top;

      // Apply circular scratch area
      this.ctx.globalCompositeOperation = "destination-out"; // Ensures overlay is removed
      this.ctx.beginPath();
      this.ctx.arc(x, y, 20, 0, Math.PI * 2); // Circular scratch area
      this.ctx.fill();

      // console.log(`Scratching at: (${x}, ${y})`); // Debugging
      this.checkReveal();
    },
    stopScratching() {
      this.isScratching = false;
    },
    checkReveal() {
      const canvas = this.$refs.scratchCanvas;
      if (!this.ctx || !canvas) return;

      const imageData = this.ctx.getImageData(
        0,
        0,
        this.canvasWidth,
        this.canvasHeight
      );
      const pixels = imageData.data;
      let scratchedPixels = 0;

      for (let i = 0; i < pixels.length; i += 4) {
        if (pixels[i + 3] === 0) scratchedPixels++; // Count fully transparent pixels
      }

      const totalPixels = pixels.length / 4;
      const scratchedPercentage = scratchedPixels / totalPixels;

      if (scratchedPercentage > this.revealThreshold) {
        this.isRevealed = true;
        this.clearCanvas();
      }
    },
    clearCanvas() {
      this.ctx.globalCompositeOperation = "source-over";
      this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);
    },
    playAgain() {
      const canvas = this.$refs.scratchCanvas;
      if (canvas) {
        const ctx = canvas.getContext("2d", { willReadFrequently: true });

        // Clear the canvas
        ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight);

        // Reset state
        this.isScratching = false;
        this.isRevealed = false;

        // Reinitialize canvas and scratch layer
        this.createScratchLayer();
      }
    },
  },
};
</script>

<style scoped>
canvas {
  cursor: pointer;
  display: block;
  margin: 0 auto;
}

.scratch-card-container {
  position: relative;
  width: 300px;
  height: 200px;
}

.scratch-card {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  cursor: pointer;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
</style>
