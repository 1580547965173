<template>
  <v-container v-if="loading" fluid fill-height>
    <v-row align="center" justify="center">
      <v-col align="center">
        <v-progress-circular
          :size="100"
          :width="8"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
    </v-row>
  </v-container>

  <v-container v-else fluid>
    <v-tabs v-model="tab" class="mt-n3 mx-n3">
      <v-tab class="tab"> Claims </v-tab>
      <v-tab class="tab"> Opportunities </v-tab>
      <v-tab class="tab" v-if="isIncentableAdmin"> Form </v-tab>

      <v-tab class="tab"> Files </v-tab>
      <v-tab class="tab"> Titles </v-tab>
      <v-tab class="tab"> Tags </v-tab>
      <v-tab class="tab"> Other </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <offer-entries />
      </v-tab-item>

      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <offer-opportunities />
      </v-tab-item>

      <v-tab-item
        v-if="isIncentableAdmin"
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <form-builder />
      </v-tab-item>

      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <offer-resources />
      </v-tab-item>

      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <offer-titles />
      </v-tab-item>

      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <offer-tags />
      </v-tab-item>

      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <offer-settings />
      </v-tab-item>
    </v-tabs-items>

    <div class="sticky-footer">
      <v-btn
        class="ml-6"
        color="primary"
        :disabled="!hasOfferPendingUpdates && !processingOffer"
        :loading="processingOffer"
        @click="handleSave"
        elevation="0"
      >
        save
      </v-btn>
      <span class="incentable-form-subheading ml-3">
        Last updated: {{ currentOffer.updated | date }}
      </span>
    </div>

    <offer-processing-dialog
      :openProcessingDialog="processingOffer && !openSyncDialog"
    ></offer-processing-dialog>

    <databucket-sync-dialog
      v-if="openSyncDialog"
      :logs="syncLogs"
      :syncSuccess="syncSuccess"
    />
  </v-container>
</template>

<script>
import OfferTitles from "./Components/Titles.vue";
import OfferTags from "./Components/Tags.vue";
import OfferSettings from "./Components/Settings.vue";
import FormBuilder from "./ClaimFormBuilder.vue";
import OfferOpportunities from "./Components/Opportunities/OfferOpportunities.vue";
import OfferEntries from "./Components/Entries/OfferEntries.vue";
import OfferProcessingDialog from "./OfferProcessingDialog.vue";
export default {
  components: {
    OfferTitles,
    OfferTags,
    OfferSettings,
    FormBuilder,
    OfferOpportunities,
    OfferEntries,
    OfferProcessingDialog,
  },
  created() {
    const offerId = this.$route.params.offerId;
    this.$store.dispatch("initialize");
    this.$store.dispatch("loadMemberTags");
    this.$store.dispatch("loadCompanyTags");
    this.$store.dispatch("loadCompanies");
    this.$store.dispatch("loadMembers");
    this.$store.dispatch("loadDatabuckets");
    this.$store.dispatch("loadCurrentOffer", offerId);
    this.$store.dispatch("setProcessingOffer", false);
  },
  data: function () {
    return {
      tab: null,
      isProcessing: false,
      openSyncDialog: false,
      syncLogs: [],
      search: false,
      searchActive: false,
      syncSuccess: false,
    };
  },
  computed: {
    currentOffer() {
      return this.$store.state.offer.currentOffer;
    },

    loading() {
      return this.$store.state.offer.loadingCurrentOffer;
    },

    processingOffer() {
      return this.$store.getters.processingOffer;
    },

    matchingData() {
      return this.$store.getters.offerMatchingData;
    },

    hasOfferPendingUpdates() {
      return this.$store.getters.hasOfferPendingUpdates;
    },
    isIncentableAdmin() {
      return this.$store.getters.isIncentableAdmin;
    },

    // hasOfferRawPendingUpdates() {
    //   return this.$store.getters.hasOfferRawPendingUpdates;
    // },
  },

  methods: {
    async handleSave() {
      this.syncSuccess = false;
      this.$store.dispatch("setProcessingOffer", true);
      const dataBucketIds = await this.$store.dispatch("updateOffer");
      // console.log(dataBucketIds);
      const syncProcess = dataBucketIds.map(async (dataBucketId) => {
        await this.$store.dispatch("loadDatabucket", dataBucketId);
        let columnDoc, totalDoc;
        try {
          ({ columnDoc, totalDoc } = await this.$store.dispatch(
            "updateResult"
          ));
        } catch (e) {
          return;
        }
        this.openSyncDialog = true;
        this.syncLogs = [
          "Please do not close the browser until processing ends.",
          "Loading Tables and Leaderboards...",
        ];
        await Promise.all([
          this.$store.dispatch("loadTablesByDatabucket", dataBucketId),
          this.$store.dispatch("loadLeaderboardsByDatabucket", dataBucketId),
        ]);
        const tables = this.$store.getters.tables;
        const leaderboards = this.$store.getters.leaderboards;
        this.syncLogs = [
          ...this.syncLogs,
          `${tables.length} tables and ${leaderboards.length} leaderboards found...`,
        ];
        for (let i = 0; i < tables.length; i += 1) {
          const table = tables[i];

          this.syncLogs = [
            ...this.syncLogs,
            `[Table] ${table.title} is processing...`,
          ];

          this.$store.dispatch("fakeLoadCurrentTable", {
            tableData: table,
            tableRawData: columnDoc,
          });

          await this.$store.dispatch("syncTable");

          this.syncLogs = [
            ...this.syncLogs,
            `[Table] ${table.title} processing completed.`,
          ];
        }
        for (let i = 0; i < leaderboards.length; i += 1) {
          const leaderboard = leaderboards[i];

          this.syncLogs = [
            ...this.syncLogs,
            `[Leaderboard] ${leaderboard.title} is processing...`,
          ];

          this.$store.dispatch("fakeLoadCurrentLeaderboard", {
            leaderboardData: leaderboard,
            leaderboardRawData: totalDoc,
          });

          await this.$store.dispatch("syncLeaderboard");

          this.syncLogs = [
            ...this.syncLogs,
            `[Leaderboard] ${leaderboard.title} processing completed.`,
          ];
        }
        this.syncLogs = [...this.syncLogs, "All completed successfully."];
      });
      await Promise.all(syncProcess);
      setTimeout(() => {
        this.syncSuccess = true;
        this.$store.dispatch("setSnackbar", "Offer updated");
        this.$store.dispatch("setProcessingOffer", false);
      }, 500);
      setTimeout(() => {
        this.syncLogs = [];
        this.openSyncDialog = false;
      }, 4000);
    },
  },
};
</script>

<style scoped>
.sticky-footer {
  position: fixed;
  bottom: 0px;
  margin-bottom: 20px;
  z-index: 1000 !important;
}
</style>
