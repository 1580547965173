<template>
  <v-dialog v-model="open" max-width="400" persistent>
    <v-card>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row no-gutters>
          <v-card-title class="page-heading ml-2">
            {{ validationRecordId ? "Edit " : "Add New " }}
            Form Data Record
          </v-card-title>
          <v-alert
            v-if="validationRecordId"
            color="primary"
            border="left"
            dense
            text
            class="ml-5"
          >
            This item has been claimed 0 times
          </v-alert>
        </v-row>

        <v-card-text class="px-9">
          <v-row no-gutters>
            <v-col>
              <v-select
                v-model="status"
                name="status"
                label="Status"
                :items="statuses"
              >
              </v-select>
              <v-text-field
                v-model="identifier"
                label="Identifier"
                name="identifier"
                id="identifier"
                hint="Optional"
              ></v-text-field>
              <v-text-field
                v-model="title"
                label="Title"
                name="title"
                id="title"
                required
                hint="Required"
                :rules="titleRules"
              ></v-text-field>
              <v-text-field
                v-model="description"
                label="Description"
                name="description"
                id="description"
                hint="Optional"
              ></v-text-field>
              <v-text-field
                v-model="points"
                label="Points"
                name="points"
                id="points"
                hint="Optional"
              ></v-text-field>
              <v-text-field
                v-model="claimsLimit"
                label="Claims Limit"
                name="claimsLimit"
                id="claimsLimit"
                hint="Optional"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col v-if="errorMessage">
              <v-alert type="error" :value="true">
                {{ errorMessage }}
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="handleClose()">Cancel</v-btn>
          <v-btn
            :class="orgTheme.buttonTextColor"
            color="primary"
            @click="handleSave()"
            :loading="loadingValidationRecord"
            elevation="0"
            :disabled="demo"
            >Save</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
    validationRecordId: {
      type: String,
      required: true,
    },
    dataSetId: {
      type: String,
      required: true,
    },
    open: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    valid: true,
    titleRules: [(v) => !!v || "Title is required"],
    search: "",
    identifier: "",
    title: "",
    points: "",
    description: "",
    claimsLimit: null,
    status: "Active",
    statuses: ["Active", "Inactive"],
    errorMessage: null,
  }),

  computed: {
    demo() {
      return this.$store.state.program.currentProgram.demo;
    },
    error() {
      return this.$store.getters.error;
    },
    loadingValidationRecord() {
      return this.$store.getters.loadingValidationRecord;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    validationDataSets() {
      return this.$store.getters.validationDataSets;
    },
    validationRecords() {
      return this.$store.getters.validationRecords;
    },
  },

  watch: {
    validationRecordId: {
      handler(newVal) {
        if (newVal && this.open) {
          const selectedValidationRecord = this.validationRecords.find(
            (item) => item.id === newVal
          );
          const {
            description,
            identifier,
            title,
            points,
            claimsLimit,
            status,
          } = selectedValidationRecord;
          this.title = title;
          this.description = description;
          this.points = points;
          this.identifier = identifier;
          this.status = status;
          this.claimsLimit = claimsLimit;
        }
      },
    },
  },

  methods: {
    handleClose() {
      this.clear();
      this.$emit("onClose");
    },

    clear() {
      this.errorMessage = null;
      this.title = "";
      this.description = "";
      this.points = "";
      this.status = "Active";
      this.claimsLimit = null;
      this.identifier = "";
      this.$refs.form.resetValidation();
      this.$store.dispatch("setLoadingValidationRecord", false);
    },

    async handleSave() {
      this.errorMessage = null;
      await this.$refs.form.validate();
      if (!this.valid) {
        return;
      }

      const payload = {
        dataSetId: this.dataSetId,
        id: this.validationRecordId || "",
        title: this.title || "",
        description: this.description || "",
        points: this.points || "",
        identifier: this.identifier || "",
        status: this.status || "Active",
        claimsLimit: this.claimsLimit || null,
        identifierUppercase: this.identifier.toUpperCase() || "",
      };

      const action = this.validationRecordId
        ? "updateValidationRecord"
        : "createValidationRecord";

      this.$store
        .dispatch(action, payload)
        .then(() => {
          this.handleClose();
        })
        .catch((e) => {
          this.errorMessage = e;
        });
    },
  },
};
</script>

<style></style>
