<template>
  <div class="px-4 pt-2 mb-8">
    <v-row no-gutters>
      <v-col cols="3">
        <v-checkbox
          label="Reward Points Display"
          v-model="rewards.active"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="3">
        <v-checkbox
          label="Rewards Shopping Cart"
          v-model="rewards.cart"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="3">
        <v-text-field
          label="Rewards Points Label"
          v-model="points.label"
        ></v-text-field>
      </v-col>
    </v-row>
    <!-- replaced by Articles Preview feature in the design editor 
    <v-row  no-gutters>
      <v-col cols="3">
        <v-select
          name="rewardCatalogues"
          :items="rewardCatalogues"
          label="Rewards Preview Catalogues"
          item-text="title"
          item-value="id"
          v-model="homeRewardCatalogues.rewardCatalogues"
          chips
          multiple
          small-chips
          deletable-chips
          hint="Select Reward Catalogues to preview on the home page"
          persistent-hint
        ></v-select>
      </v-col>
    </v-row>
    -->
    <v-btn
      class="ml-0 mt-9"
      color="primary"
      @click="handleSave"
      :loading="loading"
      elevation="0"
      >Save</v-btn
    >
  </div>
</template>

<script>
export default {
  created() {
    const { currentProgram } = this.$store.state.program;
    this.rewards = currentProgram.rewards || {};
    this.homeRewardCatalogues = currentProgram.homeRewardCatalogues || {};
    this.points = currentProgram.points || {};
    this.$store.dispatch("loadRewardCatalogues");
  },
  data: () => ({}),
  computed: {
    loading() {
      return this.$store.getters.loading;
    },
    rewardCatalogues() {
      return this.$store.state.rewardcatalogue.rewardCatalogues;
    },
  },
  methods: {
    handleSave() {
      const payload = {
        rewards: {
          active: this.rewards.active || false,
          cart: this.rewards.cart || false,
          preview: this.rewards.preview || false,
        },
        points: {
          label: this.points.label || "",
        },
        homeRewardCatalogues: {
          rewardCatalogues: this.rewardCatalogues || [],
        },
      };

      this.$store.dispatch("setLoading", true);
      this.$store
        .dispatch("updateCurrentProgram", payload)
        .then(() => {
          this.$store.dispatch("setLoading", false);
        })
        .catch(() => {
          this.$store.dispatch("setLoading", false);
        });
    },
  },
};
</script>

<style scoped></style>
