<template>
  <div>
    <v-tabs v-model="tab">
      <v-tab class="tab"> Builder </v-tab>
      <v-tab class="tab"> Form Presets Library </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <FormBuilderMain />
      </v-tab-item>

      <v-tab-item
        transition="fade-transition"
        reverse-transition="fade-transition"
      >
        <ValidationDataMain class="mr-16" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import FormBuilderMain from "./Components/FormBuilder/Main.vue";
import ValidationDataMain from "./Components/ValidationData/Main.vue";
export default {
  components: { ValidationDataMain, FormBuilderMain },
  data: function () {
    return {
      isProcessing: false,
      tab: null,
    };
  },
  computed: {
    currentOffer() {
      return this.$store.state.offer.currentOffer;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    loading() {
      return this.$store.getters.loading;
    },
  },
  methods: {},
};
</script>

<style scoped></style>
