<template>
  <div>
    <loading-cards v-if="loadingCards" :count="cardCount" cols="4" />
    <v-row v-if="!loadingCards" no-gutters class="mt-4 ml-1 mr-1">
      <v-col md="4" xs="12" class="pr-2 pl-2">
        <v-card
          style="height: 224px"
          class="mb-4 el rounded-card gradient-card-background-dark"
        >
          <v-row no-gutters justify="end">
            <v-btn
              class="manage-button white--text mr-6"
              elevation="0"
              large
              rounded
              color="primary"
              @click="setup()"
            >
              <v-icon :color="orgTheme.buttonIconColor" left size="20"
                >add</v-icon
              >
              create new
            </v-btn>
          </v-row>

          <v-card-title>
            <div class="incentable-program-card-title" @click="setup()">
              Create New Program
            </div>
          </v-card-title>

          <div class="ml-4 mt-11">
            <div class="incentable-bullet-list">
              <v-icon color="white" size="13">check</v-icon> Set program title
            </div>
            <div class="incentable-bullet-list">
              <v-icon color="white" size="13">check</v-icon> Pick a theme
            </div>
            <div class="incentable-bullet-list">
              <v-icon color="white" size="13">check</v-icon> Choose the features
              you need
            </div>
            <div class="incentable-bullet-list">
              <v-icon color="white" size="13">check</v-icon> Select a
              <a
                class="plan-link"
                target="_blank"
                href="https://incentable.com/pricing"
                >subscription plan</a
              >
              from $99 per month
            </div>
            <div class="incentable-bullet-list">
              <v-icon color="primary" size="13">emoji_events</v-icon> Launch
              your program website
            </div>
          </div>
        </v-card>
      </v-col>

      <v-col md="4" xs="12" class="pr-2 pl-2">
        <free-trial-banner />
      </v-col>

      <v-col md="4" xs="12" class="pr-2 pl-2">
        <demo-booking-banner />
      </v-col>

      <v-col
        v-for="(program, id) in filteredPrograms"
        :key="id"
        md="4"
        xs="12"
        class="pr-2 pl-2"
      >
        <v-card
          style="height: 224px"
          class="mb-4 el rounded-card gradient-card-background"
        >
          <v-row no-gutters justify="end">
            <v-btn
              class="manage-button white--text mr-6"
              elevation="0"
              color="primary"
              fab
              rounded
              :disabled="isDisabled(program.id)"
              :loading="isLoadingProgram(program.id)"
              @click="onLoadProgram(program.id)"
            >
              <v-icon :color="orgTheme.buttonIconColor">navigate_next</v-icon>
            </v-btn>
          </v-row>
          <v-card-title @click="onLoadProgram(program.id)">
            <v-clamp :max-lines="2" class="incentable-program-card-title">
              {{ program.title }}
            </v-clamp>
          </v-card-title>
          <v-card-subtitle
            class="incentable-card-subtitle"
            v-if="
              program.status === 'Active' ||
              program.status === 'Demo' ||
              program.status === 'Trial'
            "
          >
            <a
              :href="domain(program.id).index"
              class="link-light"
              target="_blank"
            >
              {{ domain(program.id).simple }}
            </a>
          </v-card-subtitle>
          <v-card-subtitle v-else class="incentable-card-subtitle">
            {{ domain(program.id).simple }}
          </v-card-subtitle>

          <v-card-actions class="pl-2 card-actions pb-2">
            <v-chip
              class="capitalize table-subtitle white--text"
              small
              color="transparent"
            >
              <v-icon
                v-if="program.status === 'Active'"
                size="14"
                class="mr-1 ml-n1"
                color="green"
                >circle</v-icon
              >
              <v-icon
                v-else-if="program.status === 'Demo'"
                size="14"
                class="mr-1 ml-n1"
                color="green"
                >circle</v-icon
              >
              <v-icon
                v-else-if="program.status === 'Trial'"
                size="14"
                class="mr-1 ml-n1"
                color="amber"
                >circle</v-icon
              >
              <v-icon v-else size="10" class="mr-1 ml-n1" color="red"
                >circle</v-icon
              >
              {{ program.status === "Trial" ? "Free Trial" : program.status }}
            </v-chip>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VClamp from "vue-clamp";
import FreeTrialBanner from "./FreeTrialBanner.vue";
import DemoBookingBanner from "./DemoBookingBanner.vue";
export default {
  components: { FreeTrialBanner, DemoBookingBanner, VClamp },
  props: ["search", "programs", "createdByMe"],
  data: () => ({
    cardCount: 9,
    loadingId: null,
  }),
  created() {},
  computed: {
    loadingCards() {
      return this.$store.getters.loadingCards;
    },
    loadingProgram() {
      return this.$store.getters.loadingProgram;
    },
    orgTheme() {
      return this.$store.getters.orgTheme;
    },
    systemTheme() {
      return this.$store.getters.systemTheme;
    },
    sites() {
      return this.$store.state.site.sites;
    },
    isIncentableAdmin() {
      return this.$store.getters.isIncentableAdmin;
    },
    subscriptions() {
      return this.$store.state.plans.subscriptions;
    },
    manualSubscriptions() {
      return this.$store.state.plans.manualSubscriptions;
    },
    userId() {
      return this.$store.getters.user.id;
    },
    filteredPrograms() {
      const arr = this.programs;
      const subset = arr.filter(
        (item) =>
          (item.status === "Active" ||
            item.status === "Demo" ||
            item.status === "Trial") &&
          (!this.createdByMe || item.createdBy === this.userId)
      );
      if (this.search.length > 0) {
        var input = this.search.toUpperCase();
        var inputLower = this.search.toLowerCase();
        var filtered = subset.filter(
          (item) =>
            item.titleUppercase.includes(input) || item.url.includes(inputLower)
        );
        return filtered;
      }
      return subset;
    },
    titleError() {
      return this.$store.getters.prgTitleExists;
    },
    urlError() {
      return this.$store.getters.prgUrlExists;
    },
    newProgramDialog() {
      return this.$store.getters.newProgramDialog;
    },
    paymentError() {
      return this.$store.getters.paymentError;
    },
  },
  methods: {
    setup() {
      this.$store.dispatch("setSetupMode", "subscribe");
    },
    domain(id) {
      const url = !this.sites.find((el) => el.program === id)
        ? "Site not found"
        : this.sites.find((el) => el.program === id && el.status === "Live")
            .activeDomain;
      return {
        index: "https://" + url,
        register: "https://" + url + "/register",
        signin: "https://" + url + "/signin",
        simple: url,
      };
    },
    subscriptionStatus() {
      return "inactive";
    },
    async onLoadProgram(programId) {
      this.loadingId = programId;
      this.$store.dispatch("setLoadingProgram", true);
      this.$store.dispatch("loadCurrentProgram", programId);
      this.$store.dispatch("selectProgram", programId);
      this.$store.dispatch("getCurrentSubscription", programId);
      await this.$router.push("/dashboard/" + programId);
      this.$store.dispatch("setLoadingProgram", false);
      // reset the table preview account when program changes
      this.$store.dispatch("setPreviewAccountKey", null);
      // reset design view settings
      this.$store.dispatch("setDesignDrawer", "Content");
      this.$store.dispatch("setSelectedPageTemplate", "Home");
      this.loadingId = null;
    },
    isLoadingProgram(id) {
      return id === this.loadingId;
    },
    isDisabled(id) {
      return this.loadingId !== null && id !== this.loadingId;
    },
  },
};
</script>

<style scoped>
.card-actions {
  position: absolute;
  bottom: 0;
}
.link-light {
  text-decoration: none !important;
  color: #ffffff;
  font-size: 14px !important;
  font-weight: 300 !important;
}
.plan-link {
  font-weight: 700 !important;
}
</style>
