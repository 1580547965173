<template>
  <div class="px-4 pt-4 mb-8">
    <v-row no-gutters class="incentable-form-heading mb-2">
      Subscription
    </v-row>
    <v-row no-gutters>
      <v-col cols="3">
        <v-select
          name="status"
          label="Program Status"
          v-model="status"
          :items="statuses"
        ></v-select>
      </v-col>
    </v-row>

    <v-row no-gutters class="incentable-form-heading mt-8 mb-0"> Theme </v-row>

    <v-row>
      <v-col sm="3">
        <v-text-field
          label="Theme Name"
          v-model="webTheme.title"
        ></v-text-field>
      </v-col>

      <v-col sm="1">
        <v-text-field label="Add Tag" v-model="newTag"></v-text-field>
      </v-col>

      <v-col sm="auto">
        <v-btn fab color="primary" class="mt-4" elevation="0" small>
          <v-icon @click="addTag(newTag)"> add </v-icon>
        </v-btn>
      </v-col>

      <v-col sm="auto" class="mt-4">
        <v-chip
          v-for="tag in webTheme.tags"
          :key="tag"
          class="mr-1"
          close
          @click:close="deleteTag(tag)"
          >{{ tag }}</v-chip
        >
      </v-col>
    </v-row>

    <v-row cols="12">
      <v-col sm="3">
        <v-text-field
          label="Preview Image Filename"
          v-model="webTheme.previewImage"
        ></v-text-field>
      </v-col>
      <v-col sm="3">
        <v-img
          v-if="webTheme.previewImage"
          :src="require('@/assets/themes/' + webTheme.previewImage)"
          contain
        >
        </v-img>
      </v-col>
    </v-row>

    <v-row no-gutters class="incentable-form-heading mt-8 mb-4"> App </v-row>

    <v-row>
      <v-col cols="3">
        <v-select
          name="iosAppStatus"
          label="iOS App Status"
          v-model="iosAppStatus"
          :items="iosAppStatuses"
        ></v-select>
      </v-col>
      <v-col cols="3">
        <v-text-field label="Apple App Store URL" v-model="appleStoreUrl" />
      </v-col>
      <v-col cols="3">
        <div class="mt-6">
          <a
            href="https://apps.apple.com/au/developer/incentable/id1461446761"
            target="_blank"
          >
            Incentable on Apple Store
          </a>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <v-select
          name="androidAppStatus"
          label="Android App Status"
          v-model="androidAppStatus"
          :items="androidAppStatuses"
        ></v-select>
      </v-col>
      <v-col cols="3">
        <v-text-field label="Google Play Store URL" v-model="googleStoreUrl" />
      </v-col>
      <v-col cols="3">
        <div class="mt-6">
          <a
            href="https://play.google.com/store/apps/developer?id=Incentable"
            target="_blank"
          >
            Incentable on Google Store
          </a>
        </div>
      </v-col>
    </v-row>

    <v-row no-gutters class="incentable-form-heading mt-8 mb-4">
      Favicon
    </v-row>
    <v-row no-gutters class="caption">
      Favicon PNG file needs to be uploaded to public/favicons
    </v-row>
    <v-row no-gutters>
      <v-col sm="3">
        <v-text-field
          label="Favicon filename"
          v-model="webTheme.favicon"
        ></v-text-field>
      </v-col>
    </v-row>

    <v-btn
      class="ml-0"
      color="primary"
      @click="handleSave"
      :loading="loading"
      elevation="0"
      >Save</v-btn
    >
  </div>
</template>

<script>
import _ from "lodash";
import moment from "moment";
export default {
  created() {
    const { currentProgram } = this.$store.state.program;
    this.status = currentProgram.status;
    this.iosAppStatus = currentProgram.iosAppStatus;
    this.androidAppStatus = currentProgram.androidAppStatus;
    this.appleStoreUrl = currentProgram.appleStoreUrl || "";
    this.googleStoreUrl = currentProgram.googleStoreUrl || "";
    this.webTheme = currentProgram.webTheme || {};
    this.tags = currentProgram.webTheme.tags || [];
    this.homeRewardCatalogues = currentProgram.homeRewardCatalogues || [];
    this.$store.dispatch("loadRewardCatalogues");
  },
  data: () => ({
    date: new Date().toISOString().substr(0, 10),
    webTheme: {},
    newTag: "",
    tags: [],
    menu: false,
    modal: false,
    status: "",
    statuses: ["Active", "Archive", "Demo", "Expired", "Theme", "Trial"],
    iosAppStatuses: ["Inactive", "Active", "Processing"],
    androidAppStatuses: ["Inactive", "Active", "Processing"],
    iosAppStatus: "",
    androidAppStatus: "",
    appleStoreUrl: "",
    googleStoreUrl: "",
    //
    isProcessing: false,
    syncSuccess: false,
    syncLogs: [],
  }),
  computed: {
    programId() {
      return this.$store.getters.programId;
    },
    rewardCatalogues() {
      return this.$store.state.rewardcatalogue.rewardCatalogues;
    },
    loading() {
      return this.$store.getters.loading;
    },
    loadingDatabucket() {
      return this.$store.getters.loadingDatabucket;
    },
  },
  methods: {
    formatDate(date) {
      return date ? moment(date).format("YYYY-MM-DD") : "";
    },
    addTag(tag) {
      const arr = this.webTheme.tags;
      arr.push(tag);
      this.newTag = "";
    },
    deleteTag(tag) {
      const arr = this.webTheme.tags;
      const ind = arr.indexOf(tag);
      if (ind > -1) {
        arr.splice(ind, 1);
      }
    },
    handleSave() {
      const payload = {
        status: this.status || "",
        iosAppStatus: this.iosAppStatus || "",
        androidAppStatus: this.androidAppStatus || "",
        appleStoreUrl: this.appleStoreUrl,
        googleStoreUrl: this.googleStoreUrl,
        webTheme: this.webTheme,
        homeRewardCatalogues: {
          rewardCatalogues: this.homeRewardCatalogues.rewardCatalogues || [],
        },
      };

      this.$store.dispatch("setLoading", true);
      this.$store
        .dispatch("updateCurrentProgram", payload)
        .then(() => {
          this.$store.dispatch("setLoading", false);
        })
        .catch(() => {
          this.$store.dispatch("setLoading", false);
        });
    },
  },
  // temp for testing quiz points updates
  watch: {
    columnData(newVal, oldVal) {
      const equality = _.isEqual(newVal, oldVal);
      if (!equality) {
        const changes = [...this.changes, "result"];
        this.$store.dispatch("updateChanges", changes);
      }
    },
    totalData(newVal, oldVal) {
      const equality = _.isEqual(newVal, oldVal);
      if (!equality) {
        const changes = [...this.changes, "result"];
        this.$store.dispatch("updateChanges", changes);
      }
    },
    pointsFormula(newVal, oldVal) {
      const equality = _.isEqual(newVal, oldVal);
      if (!equality) {
        const changes = [...this.changes, "result"];
        this.$store.dispatch("updateChanges", changes);
      }
    },
    databucket(newVal, oldVal) {
      if (
        newVal.title !== oldVal.title ||
        newVal.targetCodes !== oldVal.targetCodes ||
        newVal.displayTitle !== oldVal.displayTitle ||
        newVal.status !== oldVal.status ||
        newVal.pointsRedeemable !== oldVal.pointsRedeemable
      ) {
        const changes = [...this.changes, "meta"];
        this.$store.dispatch("updateChanges", changes);
      }

      if (!_.isEqual(newVal.previewColumns, oldVal.previewColumns)) {
        const changes = [...this.changes, "result"];
        this.$store.dispatch("updateChanges", changes);
      }
    },
  },
  // end
};
</script>

<style scoped></style>
